import React from "react";
import Outlets from "../../../client/components/views/Outlets";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import { saveOutlets } from "../../../store/actions/menuClientActions";
const DB = firebase.db;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveOutlets: (menues) => dispatch(saveOutlets(menues)),
  };
};

class OutletsContainerCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      outlets: [],
      hotel: {},
    };
  }

  componentDidMount() {
    let arrayOutlets = [];
    let outletsDoc = DB.collection("hoteles")
      .doc(`${this.props.match.params.idHotel}`)
      .collection("outlets")
      .orderBy("order", "asc");
    outletsDoc.get().then((outlets) => {
      outlets.forEach((outlet) => {
        if (outlet.data().active === true || outlet.data().active === false) {
          arrayOutlets.push({
            name: outlet.data().name,
            id: outlet.id,
            imageOutlet: outlet.data().imageOutlet,
            active: outlet.data().active
          });
        } else {
          arrayOutlets.push({
            name: outlet.data().name,
            id: outlet.id,
            imageOutlet: outlet.data().imageOutlet,
            active: true
          });
        }
      });
      this.setState({
        hotel: this.props.hotelInfoRedux,
        outlets: arrayOutlets,
        loader: false,
      });
    });
  }

  render() {
    return (
      <div>
        <Outlets
          idLanguage={this.props.match.params.idLanguage}
          idHotel={this.props.match.params.idHotel}
          outlets={this.state.outlets}
          loader={this.state.loader}
          hotel={this.props.hotelInfoRedux}
          whatsapp={this.props.hotelInfoRedux.whatsappNumber}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutletsContainerCliente);
