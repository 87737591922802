import firebase from "../../services/firebase";
import { showLoader, hideLoader } from "./loginAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
const DB = firebase.db;

export const getMenu = (hotelID) => (dispatch) => {
  let array = [];
  dispatch(showLoader());
  let doc = DB.collection("hoteles")
    .doc(hotelID)
    .collection("menues")
    .orderBy("orderMenu", "asc");
  doc.get().then((menuIndividual) => {
    menuIndividual.forEach((menuesFB) => {
      array.push({
        name: menuesFB.data().nameOfMenu,
        id: menuesFB.id,
        imageMenu: menuesFB.data().imageMenu,
        hours: menuesFB.data().hours,
        active: menuesFB.data().active,
      });
    });
    dispatch({
      type: "GET_MENU",
      menues: array,
    });
  });
  setTimeout(() => {
    dispatch(hideLoader());
  }, 500);
};

export const deleteMenu = (hotelID, id, history) => (dispatch) => {
  let doc = DB.collection("hoteles").doc(hotelID).collection("menues").doc(id);
  MySwal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
  }).then((result) => {
    if (result.value) {
      MySwal.fire("Deleted!", `Your Menu has been deleted.`, "success");
      doc.delete();
      history.push(`/menu`);
    }
  });
};
