import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Dashboard from "../../../restaurant/components/views/Dashboard";
import { connect } from "react-redux";
import { saveHotel, saveHotelId } from "../../../store/actions/hotelAction";
const DB = firebase.db;

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: {},
      loader: true,
      hotelTotalMenues: 0,
      hotelTotalCategories: 0,
      hotelTotalProducts: 0,
      hotelTotalDisableProducts: 0,
      hotels: [],
    };
  }

  componentDidMount() {
    if (this.props.hotelId !== "nohotel") {
      if (this.props.hotelId) {
        if (this.props.hotelInfoRedux.name) {
          //1. SI TIENE STATE HOTEL, SOLO MANDA AL STATE LOCAL LA INFO ESA.
          this.setState({
            hotel: this.props.hotelInfoRedux,
            hotelTotalMenues: this.props.hotelTotalMenues,
            hotelTotalCategories: this.props.hotelTotalCategories,
            hotelTotalProducts: this.props.hotelTotalProducts,
            hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
            loader: false,
          });
        } else {
          //2. SI NO LA VA A BUSCAR Y LA ENVIA AL STATE DE REDUX.
          let hotelInfo = DB.collection("hoteles").doc(`${this.props.hotelId}`);
          hotelInfo.get().then((doc) => {
            // this.props.saveHotel(doc.data());
            this.setState({
              hotel: doc.data(),
              hotelTotalMenues: this.props.hotelTotalMenues,
              hotelTotalCategories: this.props.hotelTotalCategories,
              hotelTotalProducts: this.props.hotelTotalProducts,
              hotelTotalDisableProducts: this.props.hotelTotalDisableProducts,
              loader: false,
            });
          });
        }
      }
    } else {
      let hotelInfo = DB.collection("hoteles");

      hotelInfo.onSnapshot((hotels) => {
        let hotelArray = [];
        hotels.forEach((hotel) => {
          hotelArray.push(hotel.data());
        });
        this.setState({
          hotels: hotelArray,
        });
      });

      // hotelInfo.get().then((doc) => {
      //   this.props.saveHotel(doc.data());
      //   this.setState({});
      // });
    }
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Dashboard
          hotel={this.state.hotel}
          hotelTotalMenues={this.state.hotelTotalMenues}
          hotelTotalCategories={this.state.hotelTotalCategories}
          hotelTotalProducts={this.state.hotelTotalProducts}
          hotelTotalDisableProducts={this.state.hotelTotalDisableProducts}
          loader={this.state.loader}
          rol={this.props.rol}
          name={this.props.name}
          hoteles={this.state.hotels}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //hotelId: state.user.loginUser.hotelId,
    rol: state.user.loginUser.rol,
    name: state.user.loginUser.name,
    hotelInfoRedux: state.hotel.hotelInfo,
    hotelId: state.user.loginUser.hotelId,
    hotelTotalMenues: state.hotel.totalMenues,
    hotelTotalCategories: state.hotel.totalCategories,
    hotelTotalProducts: state.hotel.totalProducts,
    hotelTotalDisableProducts: state.hotel.totalDisableProducts,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
