import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Outlet from "../../../restaurant/components/create/Outlet";
import { connect } from "react-redux";
import { saveAddMenuQuantity } from "../../../store/actions/hotelAction";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.isAuth,
    userLogin: state.user.loginUser.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveAddMenuQuantity: (hotel) => dispatch(saveAddMenuQuantity(hotel)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

class CreateOutletContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      imageOutlet: "",
      arrayMenues: [],
      order:"1",

      hotel: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }
  componentDidMount() {
    if (this.props.hotelInfoRedux.name) {
      this.setState({
        hotel: this.props.hotelInfoRedux,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.showLoader();
    let doc = DB.collection("hoteles")
      .doc(`${this.props.userLogin}`)
      .collection("outlets")
      .doc();
    doc
      .set({
        name: this.state.name,
        arrayMenues: this.state.arrayMenues,
        imageOutlet: this.state.imageOutlet,
        order: this.state.order,
      })
      .then((creado) => {
        this.props.hideLoader();

        this.props.history.push("/outlets");
        firebase.succesfullMsg("Outlet successfully created!");
      });
  }

  handleInput(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Outlet submit={this.handleSubmit} input={this.handleInput} />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOutletContainer);
