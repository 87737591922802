import React from "react";
import firebase from "../../../services/firebase";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { withRouter } from "react-router-dom";

const RecoverPassword = (props) => {
  let email = "";
  const MySwal = withReactContent(Swal);

  const handleInput = (e) => {
    email = e.target.value;
  };
  const handlerClick = function (e) {
    e.preventDefault();
    let auth = firebase.auth;
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        MySwal.fire("Please go checkout your email");
        props.history.push("/login");
      })
      .catch(function (error) {
        MySwal.fire(error.message);
      });
  };
  return (
    <div>
      <div
        className="row align-items-center"
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <div
          className="col"
          style={{
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-login.png?alt=media&token=1d4690cf-c0fe-45c1-b7de-c2c62280d047"})`,

            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            //backgroundSize: "100% auto",
            //  border: "1px solid red",
          }}
        ></div>
        <div className="col">
          <form
            onSubmit={(e) => handlerClick(e)}
            style={{
              padding: "40px",
              marginRight: "20px",
            }}
          >
            <div className="form-group">
              <h1
                className="font-weight-bold"
                style={{
                  paddingBottom: "10px",
                }}
              >
                Recuperar Contraseña
              </h1>
              <p
                id="emailHelp"
                className="form-text text-muted"
                style={{
                  fontSize: "15px",
                  marginBottom: "30px",
                }}
              >
                Para recuperar su contraseña, ingrese el email correspondiente.
                Revise su carpeta de spam si no lo encuentra en la casilla de
                emails recibidos.
              </p>
              <div
                className="form-group"
                style={{
                  paddingBottom: "20px",
                }}
              >
                <label>Correo electrónico</label>
                <input
                  onChange={(e) => handleInput(e)}
                  name="email"
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="email@lovable.com"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#FF2068",
                  borderColor: "#FF2068",
                  width: "220px",
                }}
              >
                Recuperar contraseña
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RecoverPassword);
