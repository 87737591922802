import React from "react";
import firebase from "../../../services/firebase";
import Products from "../../components/views/Products";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { connect } from "react-redux";
import { saveProduct } from "../../../store/actions/cartClient";

const DB = firebase.db;
const MySwal = withReactContent(Swal);

let orderToUpdate;
let resAddToCart;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
    language: state.hotel.language,
  };
};

class ProductContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      product: {},
      order: {
        numberOfTable: "",
        status: "draft",
        totalPrice: 0,
        date: "",
        notify: false,
      },
      comments: "Sin comentarios.",
      value: 1,
      loader: true,
      hotel: {},
      language: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.addProd = this.addProd.bind(this);
    this.lessProd = this.lessProd.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount() {
    if (!this.props.hotelId) {
      this.setState({
        hotel: { primaryColor: "#339AE7" },
        language: this.props.match.params.idLanguage,
      });

      const productDoc = DB.collection("hoteles")
        .doc(this.props.match.params.idHotel)
        .collection("menues")
        .doc(this.props.match.params.idMenu)
        .collection("categories")
        .doc(this.props.match.params.idCategoria);
      //ACA TRAER LA CATEGORIA Y FILTRAR EL INDEX
      productDoc.get().then((productDocument) => {
        let productFilter;
        productFilter = productDocument.data().products[
          this.props.match.params.idProduct
        ];
        this.setState({
          product: productFilter,
          loader: false,
        });
      });
    } else {
      this.setState({
        language: this.props.match.params.idLanguage,
      });

      const productDoc = DB.collection("hoteles")
        .doc(this.props.match.params.idHotel)
        .collection("menues")
        .doc(this.props.match.params.idMenu)
        .collection("categories")
        .doc(this.props.match.params.idCategoria);
      //ACA TRAER LA CATEGORIA Y FILTRAR EL INDEX
      productDoc.get().then((productDocument) => {
        let productFilter;
        productFilter = productDocument.data().products[
          this.props.match.params.idProduct
        ];
        this.setState({
          product: productFilter,
          hotel: this.props.hotelInfoRedux,
          loader: false,
        });
      });
    }

    // .then((querySnapshot) =>
    //   this.setState({
    //     product: {
    //       description: querySnapshot.data().description,
    //       imageProduct: querySnapshot.data().imageProduct,
    //       name: querySnapshot.data().name,
    //       price: querySnapshot.data().price,
    //       stock: querySnapshot.data().stock,
    //       nameSpanish: querySnapshot.data().nameSpanish,
    //       nameEnglish: querySnapshot.data().nameEnglish,
    //       namePortuguese: querySnapshot.data().namePortuguese,
    //       nameFrench: querySnapshot.data().nameFrench,
    //       descriptionSpanish: querySnapshot.data().descriptionSpanish,
    //       descriptionEnglish: querySnapshot.data().descriptionEnglish,
    //       descriptionPortuguese: querySnapshot.data().descriptionPortuguese,
    //       descriptionFrench: querySnapshot.data().descriptionFrench,
    //     },
    //   })
    // )
    // .then(() => {
    //   this.setState({ });
    // });
    //   }
  }

  handlerChange(e) {
    e.preventDefault();
    this.setState({ comments: e.target.value });
  }

  handleClick(e) {
    e.preventDefault();
    //PARA SETEAR LOS PRODUCTOS MAS VENDIDOS

    // const productDoc = DB.collection("hoteles")
    //   .doc(this.props.match.params.idHotel)
    //   .collection("menues")
    //   .doc(this.props.match.params.idMenu)
    //   .collection("categories")
    //   .doc(this.props.match.params.idCategoria)
    //   .collection("products")
    //   .doc(this.props.match.params.idProduct);

    // productDoc.get().then((data) => {
    //   productDoc.update({
    //     numberOfBuys: data.data().numberOfBuys + this.state.value,
    //   });
    // });

    let HotelId = this.props.match.params.idHotel;
    resAddToCart = true;
    MySwal.fire({
      title: "Agregado!",
      text: "Este producto fue agregado a tu orden.",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#ff2068",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
    }).then(() => {
      ///cabmbiar todo esto a Redux. Agregar cada producto a un objeto en redux.

      const product = {
        name: this.state.product.name,
        imageProduct: this.state.product.imageProduct,
        price: this.state.product.price,
        cantidad: this.state.value,
        comentarios: this.state.comments,
      };

      this.props.saveProduct(product);

      if (resAddToCart) {
        let OrdersRestaurant = DB.collection("hoteles")
          .doc(HotelId)
          .collection("orders")
          .doc(`${orderToUpdate}`);
        this.setState((state) => ({
          product: {
            ...state.product,
            comments: state.comments,
            quantity: this.state.value,
          },
        }));
        OrdersRestaurant.collection("products").doc().set(this.state.product);
      }
    });
  }

  addProd(e) {
    e.preventDefault();
    this.setState({ value: this.state.value + 1 });
  }

  lessProd(e) {
    e.preventDefault();
    if (this.state.value > 1) this.setState({ value: this.state.value - 1 });
  }

  handleGoBack() {
    this.props.history.goBack();
  }


  render() {
    return (
      <div>
        <Products
          lessProd={this.lessProd}
          value={this.state.value}
          addProd={this.addProd}
          language={this.state.language}
          handlerChange={this.handlerChange}
          handleClick={this.handleClick}
          product={this.state.product}
          loader={this.state.loader}
          hotel={this.state.hotel}
          whatsapp={this.state.hotel.whatsappNumber}
          idHotel={this.props.match.params.idHotel}
          handleGoBack={this.handleGoBack}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveProduct: (product) => dispatch(saveProduct(product)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
