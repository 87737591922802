import React from "react";
import { Link } from "react-router-dom";

export default ({
  products,
  menuId,
  catId,
  deleteFunc,
  handleStock,
  images,
  rol,
  handleEdit,
}) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginLeft: "250px",
        paddingBottom: "400px",
      }}
    >
      <div
        className="container"
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingTop: "20px",
        }}
      >
        <h1 className="font-weight-bold">Products</h1>

        {rol === "admin" ? (
          <Link
            style={{
              textDecoration: "none",
              color: "#ffffff",
              backgroundColor: "#ff2068",
              borderColor: "#ff2068",
              marginTop: "20px",
              marginBottom: "10px",
            }}
            className="btn btn-primary"
            to={`/menu/${menuId}/${catId}/createProduct`}
          >
            Add Products
          </Link>
        ) : (
          <></>
        )}

        <hr />

        <ul
          className="list-group"
          style={{
            marginTop: "30px",
          }}
        >
          {products
            ? products.map((individual, index) =>
              !individual.stock ? (
                <li
                  key={individual.name}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  style={{ padding: "20px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 8,
                      flexDirection: "row",
                      //maxWidth: "80%",
                    }}
                  >
                    {images ? (
                      <img
                        style={{
                          maxWidth: "100px",
                          //height: "auto",
                          marginRight: "20px",
                        }}
                        src={individual.imageProduct}
                        alt="category img"
                      />
                    ) : (
                      <></>
                    )}

                    <div
                      style={{
                        marginTop: "10px",
                        //maxWidth: "90%",
                      }}
                    >
                      <h5 className="font-weight-bold">{individual.name}</h5>
                      <h6 className="font-weight-normal">
                        {individual.description}
                      </h6>
                      {/* <h5 className="font-weight-normal">Disable</h5> */}

                      {individual.price !== "" ? (
                        <h6 className="font-weight-normal">
                          Price: ${individual.price}
                        </h6>
                      ) : (
                        <h6 className="font-weight-normal">No price.</h6>
                      )}

                      {individual.glutenFree ? (
                        <img
                          style={{
                            marginRight: "10px",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                          height="20"
                          alt="gluten-free-icon"
                        />
                      ) : (
                        <></>
                      )}

                      {individual.vegetarian ? (
                        <img
                          style={{
                            marginRight: "10px",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                          height="20"
                          alt="vegetarian-icon"
                        />
                      ) : (
                        <></>
                      )}

                      {individual.favourite ? (
                        <img
                          style={{
                            marginRight: "10px",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                          height="20"
                          alt="favourite-icon"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flex: 4,
                      justifyContent: "flex-end",
                    }}
                  >
                    {rol === "admin" ? (
                      <button
                        onClick={(e) => handleEdit(e, index)}
                        style={{
                          marginLeft: "30px",
                          backgroundColor: "#339AE7",
                          borderColor: "#339AE7",
                          marginRight: "20px",
                        }}
                        className="btn btn-primary btn-lg"
                      >
                        Edit
                      </button>
                    ) : (
                      <></>
                    )}

                    <button
                      onClick={(e) => handleStock(e, index)}
                      style={{
                        backgroundColor: "#2EC4B6",
                        borderColor: "#2EC4B6",
                        marginRight: "20px",
                        width: "100px",
                      }}
                      className="btn btn-primary btn-lg"
                    >
                      Enable
                    </button>

                    {rol === "admin" ? (
                      <button
                        onClick={(e) => deleteFunc(e, index)}
                        style={{
                          backgroundColor: "#ff2068",
                          borderColor: "#ff2068",
                        }}
                        className="btn btn-primary btn-lg"
                      >
                        Delete
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </li>
              ) : (
                <li
                  key={individual.name}
                  style={{ padding: "20px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 8,
                      flexDirection: "row",
                      //maxWidth: "80%",
                    }}
                  >
                    {images ? (
                      <img
                        style={{
                          maxWidth: "100px",
                          //height: "auto",
                          marginRight: "20px",
                        }}
                        src={individual.imageProduct}
                        alt="category img"
                      />
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        marginTop: "10px",
                        //flexGrow: "4",
                      }}
                    >
                      <h5 className="font-weight-bold">{individual.name}</h5>
                      <h6 className="font-weight-normal">
                        {individual.description}
                      </h6>

                      {individual.price !== "" ? (
                        <h6 className="font-weight-normal">
                          Price: ${individual.price}
                        </h6>
                      ) : (
                        <h6 className="font-weight-normal">No price.</h6>
                      )}
                      {individual.glutenFree ? (
                        <img
                          style={{
                            marginRight: "10px",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                          height="20"
                          alt="gluten-free-icon"
                        />
                      ) : (
                        <></>
                      )}

                      {individual.vegetarian ? (
                        <img
                          style={{
                            marginRight: "10px",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                          height="20"
                          alt="gluten-free-icon"
                        />
                      ) : (
                        <></>
                      )}

                      {individual.favourite ? (
                        <img
                          style={{
                            marginRight: "10px",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                          height="20"
                          alt="gluten-free-icon"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flex: 4,
                        justifyContent: "flex-end",
                      }}
                    >
                      {rol === "admin" ? (
                        <button
                          onClick={(e) => handleEdit(e, index)}
                          style={{
                            marginLeft: "30px",
                            backgroundColor: "#339AE7",
                            borderColor: "#339AE7",
                            marginRight: "20px",
                          }}
                          className="btn btn-primary btn-lg"
                        >
                          Edit
                        </button>
                      ) : (
                        <></>
                      )}

                      <button
                        onClick={(e) => handleStock(e, index)}
                        style={{
                          backgroundColor: "#ff2068",
                          borderColor: "#ff2068",
                          marginRight: "20px",
                          width: "100px",
                        }}
                        className="btn btn-primary btn-lg"
                      >
                        Disable
                      </button>

                      {rol === "admin" ? (
                        <button
                          onClick={(e) => deleteFunc(e, index)}
                          style={{
                            backgroundColor: "#ff2068",
                            borderColor: "#ff2068",
                          }}
                          className="btn btn-primary btn-lg"
                        >
                          Delete
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </li>
              )
            )
            : null}
        </ul>
      </div>
    </div>
  );
};
