import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Outlet from "../../../restaurant/components/edit/Outlet";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../store/actions/loginAction";
const DB = firebase.db;

const mapStateToProps = (state) => {
  return {
    hotelId: state.user.loginUser.hotelId,
    isAuth: state.user.isAuth,
  };
};

class EditOutletContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      imageOutlet: "",
      order: "",
    };
    this.handleInputs = this.handleInputs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  outletId = this.props.match.params.id;
  componentDidMount() {
    if (this.props.hotelId) {
      this.props.dispatch(showLoader());
      let doc = DB.collection("hoteles")
        .doc(`${this.props.hotelId}`)
        .collection("outlets")
        .doc(this.outletId);
      doc.get().then((outlet) => {
        this.setState({
          name: outlet.data().name,
          imageOutlet: outlet.data().imageOutlet,
          order: outlet.data().order,
        });
        setTimeout(() => {
          this.props.dispatch(hideLoader());
        });
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(showLoader());
    let doc = DB.collection("hoteles")
      .doc(`${this.props.hotelId}`)
      .collection("outlets")
      .doc(this.outletId);
    doc.update(this.state).then((actualizado) => {
      this.props.dispatch(hideLoader());

      this.props.history.push(`/outlets`);
      firebase.succesfullMsg("Outlet updated!");
    });
  }

  handleInputs(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Outlet
          inputs={this.handleInputs}
          submit={this.handleSubmit}
          menu={this.state}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(EditOutletContainer);
