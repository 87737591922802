import React from "react";
import firebase from "../../../services/firebase";
import HomeHotelFull from "../../../client/components/views/HomeHotelFull";
import { connect } from "react-redux";
import {
  saveHotel,
  saveHotelId,
  saveLanguage,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../../restaurant/components/FullPageLoader/FullPageLoader";

const DB = firebase.db;
const MySwal = withReactContent(Swal);

//let roomsOfHotel;
let hotelInfo;
let control;
//let controlPay;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    saveLanguage: (HotelId) => dispatch(saveLanguage(HotelId)),
  };
};

class ClientViewHomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: {},
      hotel: {},
      loader: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    //CUANDO SE INICIA EL COMPONENTE:
    let HotelId = this.props.match.params.idHotel;


    this.props.saveHotelId(HotelId);
    //0. preguntar primero por algo para verificar que ya cargo el state.
    // if (this.props.hotelId) {
    // if (this.props.hotelInfoRedux.name) {
    //console.log("llegue despues");
    //1. SI TIENE STATE HOTEL, SOLO MANDA AL STATE LOCAL LA INFO ESA.
    //   this.setState({ hotel: this.props.hotelInfoRedux, loader: false });
    //  } else {
    //console.log("llegue primero");
    //2. SI NO LA VA A BUSCAR Y LA ENVIA AL STATE DE REDUX.
    hotelInfo = DB.collection("hoteles").doc(
      `${this.props.match.params.idHotel}`
    );
    hotelInfo.get().then((doc) => {
      this.props.saveHotel(doc.data());
      this.setState({ hotel: doc.data(), loader: false });
    });
    // }
    // }

    // hotelInfo.onSnapshot((docSnapshot) => {
    //   this.setState({ hotel: docSnapshot.data(), loader: false });
    // });

    // this.props.dispatch(showLoader());
    

 let roomsOfHotel = DB.collection("hoteles")
       .doc(`${this.props.match.params.idHotel}`)
       .collection("rooms").doc(`${this.props.match.params.idRoom}`)

    roomsOfHotel.onSnapshot((docSnapshot) => {
        this.setState({
            room: {
              clientActual: docSnapshot.data().clientActual,
              number: docSnapshot.data().number,
              orderActual: docSnapshot.data().orderActual,
              secretCode: docSnapshot.data().secretCode,
              state: docSnapshot.data().state,
              clean: docSnapshot.data().clean,
              pay: docSnapshot.data().pay,
              orderStatus: docSnapshot.data().orderStatus,
              noDisturb: docSnapshot.data().noDisturb,
              appInService: docSnapshot.data().appInService,
              id: docSnapshot.id,
            },
          });
    });
  }

   handleClick(type) {

    // if (
    //   type === "spanish" ||
    //   type === "english" ||
    //   type === "portuguese" ||
    //   type === "french"
    // ) {
    //   this.props.saveLanguage(type);
    // }

    let roomActual = DB.collection("hoteles").doc(
      `${this.props.match.params.idHotel}/rooms/${this.props.match.params.idRoom}`
    );


    if (type === "doNotDisturb") {
        this.state.room.noDisturb === false
          ? roomActual.update({ noDisturb: true })
          : roomActual.update({ noDisturb: false });
      }



    if (type === "clean"&& this.state.room.clean===false) {
        if (
            this.props.match.params.idLanguage === "spanish"   
        ) {
      MySwal.fire({
            title: "¿Estás seguro que deseas solicitar limpieza?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2EC4B6",
            cancelButtonColor: "#ff2068",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.value) {
              MySwal.fire({
                title: "Solicitado!",
                text: "Has solicitado la limpieza exitosamente.",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#ff2068",
                cancelButtonColor: "#d33",
                confirmButtonText: "Continuar",
              });
              this.state.room.clean === false
              ? roomActual.update({ clean: true })
              : roomActual.update({ clean: false });
            }
          });
        }
    
        if (
            this.props.match.params.idLanguage === "english" || this.props.match.params.idLanguage === "portuguese"|| this.props.match.params.idLanguage === "french"
        ) {
      MySwal.fire({
            title: "Are you sure you want to request Clean Service?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2EC4B6",
            cancelButtonColor: "#ff2068",
            confirmButtonText: "Confirm",
          }).then((result) => {
            if (result.value) {
              MySwal.fire({
                title: "Success!",
                text: "Clean Service has been to request.",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#ff2068",
                cancelButtonColor: "#d33",
                confirmButtonText: "Continue",
              });
              this.state.room.clean === false
        ? roomActual.update({ clean: true })
        : roomActual.update({ clean: true });
            }
          });
        }
    }




    if (type === "clean"&& this.state.room.clean===true) {
        roomActual.update({ clean: false })

    }

    if  (type === "payment" && this.state.room.pay===false) {
    if (
        this.props.match.params.idLanguage === "spanish"   
    ) {
  MySwal.fire({
        title: "¿Estás seguro que deseas realizar el Checkout?",
        text: "Esta acción no se puede revertir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2EC4B6",
        cancelButtonColor: "#ff2068",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          MySwal.fire({
            title: "Solicitado!",
            text: "Has solicitado el Checkout exitosamente.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ff2068",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continuar",
          });
          if (this.state.room.pay === false) {
            roomActual.update({ pay: true });
            // this.props.history.push(
            //   `/${this.props.match.params.idHotel}/${this.props.match.params.idRoom}/mail`
            // );
          }else{
            roomActual.update({ pay: false });
          }
        }
      });
    }

    if (
        this.props.match.params.idLanguage === "english" || this.props.match.params.idLanguage === "portuguese"|| this.props.match.params.idLanguage === "french"
    ) {
  MySwal.fire({
        title: "Are you sure you want to request Checkout?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2EC4B6",
        cancelButtonColor: "#ff2068",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.value) {
          MySwal.fire({
            title: "Success!",
            text: "Your Checkout has been to request.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ff2068",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
          });
          if (this.state.room.pay === false) {
            roomActual.update({ pay: true });
            // this.props.history.push(
            //   `/${this.props.match.params.idHotel}/${this.props.match.params.idRoom}/mail`
            // );
          }else{
            roomActual.update({ pay: false });
          }
        }
      });
    }
    }

    if (type === "payment"&& this.state.room.pay===true && this.props.match.params.idHotel==="EWAhAiiIZ4ERD7caBlkR") {
        roomActual.update({ pay: false })

    }



  }

  render() {
    return (
      <div>
        <HomeHotelFull
           handleClick={this.handleClick}
          room={this.state.room}
          hotel={this.state.hotel}
          propsOfHotelId={this.props.match.params.idHotel}
          propsOfRoomId={this.props.match.params.idRoom}
          loader={this.state.loader}
          whatsapp={this.state.hotel.whatsappNumber}
          language={this.props.match.params.idLanguage}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

//export default ClientViewContainer;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientViewHomeContainer);
