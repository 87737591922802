import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
// "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-app.png?alt=media&token=18234069-f4d0-46c7-b98e-6978e6ef68e3";

const styles = {
  container: {},
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  containerInService: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  titleInService: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    //margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,
  },

  top: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    marginLeft: "30px",
    //  backgroundColor: "green",
  },
  topCenter: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },
  notifications: {
    flex: 1.5,
    display: "flex",
    justifyContent: "center",
    //flexFlow: "column",
    alignItems: "center",
    backgroundColor: "black",
    borderRadius: "40px 40px 0 0",
    paddingBottom: "10px",
  },
  notificationsActive: {
    flex: 1.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //flexFlow: "column",
    backgroundColor: "#ff2068",
    borderRadius: "40px 40px 0 0",
    // paddingBottom: "50px",
  },

  marketingTitle: {
    margin: 0,
    marginTop: "10px",
    //fontSize: 25,
    fontWeight: 400,

    textAlign: "center",
    color: "#ffffff",

    fontSize: 55,
    fontFamily: "SimpleJoys",
    // backgroundColor: "#ff2068",
  },

  notificationsTitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,

    textAlign: "center",
    color: "#ffffff",

    //fontSize: 65,
    //fontFamily: "LoewMedium",
    // backgroundColor: "#ff2068",
  },

  hotelInfoSupport: {
    flex: 1.5,
    display: "flex",
    // justifyContent: "center",
    // flexFlow: "column",
    // alignItems: "center",
    backgroundColor: "black",
  },
  hotelInfo: {
    flex: 12,
    display: "flex",
    justifyContent: "space-around",
    //flexFlow: "column",
    alignItems: "center",
    //backgroundColor: "grey",
    backgroundColor: "white",
    borderRadius: "40px 40px 0 0",
  },
  logo: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    // height: "180px",
    //widht: "auto",
    alignItems: "center",
    // backgroundColor: "#ff2068",
    // paddingTop: "12px",
    // paddingBottom: "12px",
    // borderRadius: "50px",
  },
  buttons: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  buttons2: {
    flex: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "white",
    paddingTop: "10px",
  },

  buttonsBlack: {
    flex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    // backgroundColor: "white",
    // paddingTop: "10px",
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "5%",
    paddingBottom: "5%",
    // paddingTop: "22px",
    // paddingBottom: "22px",

    width: "44.5vw",

    backgroundColor: "#E2E6EF",
    border: "none",
    // backgroundColor: "white",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  button2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "10%",
    paddingBottom: "10%",

    // paddingTop: "22px",
    //  paddingBottom: "22px",
    //paddingTop: "65px",
    // paddingBottom: "65px",
    width: "44.5vw",

    backgroundColor: "#E2E6EF",
    border: "none",
    //borderColor: "#E2E6EF",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  buttonBlack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "6%",
    paddingBottom: "6%",

    // paddingTop: "22px",
    //  paddingBottom: "22px",
    //paddingTop: "65px",
    // paddingBottom: "65px",
    width: "44.5vw",
    border: "none",
    // backgroundColor: "#E2E6EF",
    backgroundColor: "rgba(230,230,230,0)",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  buttonActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "45px",
    paddingTop: "22px",
    paddingBottom: "22px",
    backgroundColor: "#ff2068",
    // backgroundColor: "white",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
  },

  roomTitle: {
    margin: 0,
    //  fontSize: 45,
    fontSize: 65,
    fontFamily: "SimpleJoys",
    fontWeight: "bold",
    textAlign: "center",
    color: "#ffffff",
    //fontFamily: "hiltonFont,Arial, sans-serif",
  },
  roomSubtitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: "#ffffff",
    //fontSize: 65,
    fontFamily: "LoewMedium",
  },
  optionsTitle: {
    flex: 1,
    margin: 0,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
  buttonText: {
    flex: 1,
    margin: 0,
    fontSize: 15,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  roomNumber: {
    margin: 0,
    //fontFamily: "Lato",
    fontSize: 35,
    fontWeight: 900,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
};

export default ({
  handleClick,
  room,
  hotel,
  whatsapp,
  propsOfHotelId,
  propsOfRoomId,
  loader,
}) => {
  return (
    <>
      {loader == false ? (
        <div>
          {hotel.appInService == true ? (
            <>
              {propsOfHotelId === "1HNJiY6t2xBw5c3zjV3R" ? (
                <div style={styles.containerInService}>
                  <h1 style={styles.titleInService}>Lovable Hotels</h1>
                </div>
              ) : (
                <div style={styles.containerInService}>
                  <h1 style={styles.titleInService}>En mantenimiento 🏨</h1>
                </div>
              )}
            </>
          ) : (
            <>
              {propsOfHotelId === "jHTRErn19NGhDftfnyZg" || propsOfHotelId === "TWdKMA7PTFfPPG7wdwwu" ? (
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${hotel.backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div style={styles.topCenter}>
                    <img src={hotel.logoImage} height="100" alt="Logo" />
                  </div>

                  <div style={styles.center}>
                    <p style={styles.marketingTitle}>{hotel.infoApp}</p>
                  </div>

                  <>
                    {hotel.twoLanguages === true ? (
                      <div style={styles.buttonsBlack}>
                        <button
                          style={styles.buttonBlack}
                          to={`/${propsOfHotelId}/spanish/2812/menu`}
                          onClick={() => handleClick("spanish")}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-espan%CC%83a.png?alt=media&token=4374b83f-c3d8-4a92-a97c-7b8b50e1fe06"
                            height="100"
                            alt=""
                          />
                        </button>

                        <button
                          style={styles.buttonBlack}
                          to={`/${propsOfHotelId}/english/2812/menu`}
                          onClick={() => handleClick("english")}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-usa.png?alt=media&token=4562245a-8a51-4198-b298-938ad1e0ed50"
                            height="100"
                            alt=""
                          />
                        </button>
                      </div>
                    ) : (
                      <div style={styles.buttons}>
                        <button
                          style={styles.button}
                          to={`/${propsOfHotelId}/spanish/2812/menu`}
                          onClick={() => handleClick("spanish")}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-espan%CC%83a.png?alt=media&token=4374b83f-c3d8-4a92-a97c-7b8b50e1fe06"
                            height="100"
                            alt=""
                          />
                        </button>

                        <button
                          style={styles.button}
                          to={`/${propsOfHotelId}/english/2812/menu`}
                          onClick={() => handleClick("english")}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-Panama%2Fflag-usa.png?alt=media&token=4562245a-8a51-4198-b298-938ad1e0ed50"
                            height="100"
                            alt=""
                          />
                        </button>

                        <button
                          style={styles.button}
                          to={`/${propsOfHotelId}/portuguese/2812/menu`}
                          onClick={() => handleClick("portuguese")}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-brasil.png?alt=media&token=4cd17411-a6cc-4c07-bf53-d3d7264d51ce"
                            height="100"
                            alt=""
                          />
                        </button>

                        <button
                          style={styles.button}
                          to={`/${propsOfHotelId}/french/2812/menu`}
                          onClick={() => handleClick("french")}
                        >
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-france.png?alt=media&token=258b78c2-b996-4a70-a1da-bdd546a2014f"
                            height="100"
                            alt=""
                          />
                        </button>
                      </div>
                    )}
                  </>
                </div>
              ) : (
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${hotel.backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >





                  {propsOfHotelId === "EWAhAiiIZ4ERD7caBlkR" ? (<div style={styles.top}>
                    <h1 style={styles.roomTitle}>Welcome!</h1>
                    <h6 style={styles.roomSubtitle}>Thank you for choosing us.</h6>
                  </div>
                  ) : (<div style={styles.top}>
                    <h1 style={styles.roomTitle}>{hotel.titleApp}</h1>
                    <h6 style={styles.roomSubtitle}>{hotel.subtitleApp}</h6>
                  </div>
                  )}



                  {hotel.solutionWhatsapp === true ? (
                    <Link
                      style={styles.buttonFloat}
                    //to={() => (window.location.href = "http://domain.com")}
                    >
                      <img
                        onClick={() =>
                          (window.location.href = `https://wa.me/%2B${whatsapp}`)
                        }
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                        height="60"
                        alt="whatsapp-icon"
                      />
                    </Link>
                  ) : (
                    <></>
                  )}



                  {propsOfHotelId === "EWAhAiiIZ4ERD7caBlkR" ? (<div style={styles.notifications}>
                    <p style={styles.notificationsTitle}>Try our new menu!</p>
                  </div>
                  ) : (<div style={styles.notifications}>
                    <p style={styles.notificationsTitle}>{hotel.infoApp}</p>
                  </div>
                  )}



                  <div style={styles.hotelInfoSupport}>
                    <div style={styles.hotelInfo}>
                      {/* <p style={styles.optionsTitle}>Hotel Info</p> */}

                      {hotel.solutionInformationHotel === true && hotel.webURL !== "" ? (
                        <Link>
                          <img
                            onClick={() =>
                              (window.location.href = `${hotel.webURL}`)
                            }
                            src={hotel.logoImage}
                            height="60"
                            alt="Logo"
                          />
                        </Link>
                      ) : (
                        <div style={styles.logo}>
                          {/* <Link
              style={styles.optionsTitle}
              to={`/${propsOfHotelId}/${propsOfRoomId}/information`}
            > */}
                          <img src={hotel.logoImage} height="60" alt="Logo" />
                          {/* </Link> */}
                        </div>
                      )}
                    </div>
                  </div>








                  {hotel.outlets === true ? (
                    <>
                      {hotel.twoLanguages === true ? (
                        <div style={styles.buttons2}>
                          {propsOfHotelId === "1HNJiY6t2xBw5c3zjV3R" ? (
                            <button
                              style={styles.button2}
                              to={`/${propsOfHotelId}/spanish/outlets`}
                              onClick={() => handleClick("spanish")}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-colombia.png?alt=media&token=079ecd38-ebce-48b3-abfa-0a503b7df197"
                                height="100"
                                alt=""
                              />
                            </button>
                          ) : (
                            <>
                              {propsOfHotelId === "fNIFexhS3r0NsJl5qRhn" ? (
                                <button
                                  style={styles.button2}
                                  to={`/${propsOfHotelId}/spanish/outlets`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-argentina.png?alt=media&token=13fb0249-52c9-4f57-97e7-00c4aec2a2e1"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              ) : (
                                <button
                                  style={styles.button2}
                                  // to={`/${propsOfHotelId}/spanish/outlets`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-espan%CC%83a.png?alt=media&token=d1331b88-5303-411e-bd66-a769488ec2dc"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              )}
                            </>
                          )}

                          <button
                            style={styles.button2}
                            // to={`/${propsOfHotelId}/english/outlets`}
                            onClick={() => handleClick("english")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-usa.png?alt=media&token=e6526e23-7e5b-49a7-91db-fb082c672b23"
                              height="100"
                              alt=""
                            />
                          </button>

                          {/* <Link style={styles.button} to="/forms">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Frealizar-checkout-black.png?alt=media&token=24a9e6f4-50e3-45ad-9e30-405724d2776b"
          style={{}}
          height="100"
          alt=""
        />
      </Link> */}
                        </div>
                      ) : (
                        <div style={styles.buttons}>
                          {propsOfHotelId === "1HNJiY6t2xBw5c3zjV3R" ? (
                            <button
                              style={styles.button}
                              to={`/${propsOfHotelId}/spanish/outlets`}
                              onClick={() => handleClick("spanish")}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-colombia.png?alt=media&token=079ecd38-ebce-48b3-abfa-0a503b7df197"
                                height="100"
                                alt=""
                              />
                            </button>
                          ) : (
                            <>
                              {propsOfHotelId === "fNIFexhS3r0NsJl5qRhn" ? (
                                <button
                                  style={styles.button}
                                  to={`/${propsOfHotelId}/spanish/outlets`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-argentina.png?alt=media&token=13fb0249-52c9-4f57-97e7-00c4aec2a2e1"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              ) : (
                                <button
                                  style={styles.button}
                                  to={`/${propsOfHotelId}/spanish/outlets`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-espan%CC%83a.png?alt=media&token=d1331b88-5303-411e-bd66-a769488ec2dc"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              )}
                            </>
                          )}

                          <button
                            style={styles.button}
                            to={`/${propsOfHotelId}/english/outlets`}
                            onClick={() => handleClick("english")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-usa.png?alt=media&token=e6526e23-7e5b-49a7-91db-fb082c672b23"
                              height="100"
                              alt=""
                            />
                          </button>

                          <button
                            style={styles.button}
                            to={`/${propsOfHotelId}/portuguese/outlets`}
                            onClick={() => handleClick("portuguese")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-brasil.png?alt=media&token=4cd17411-a6cc-4c07-bf53-d3d7264d51ce"
                              height="100"
                              alt=""
                            />
                          </button>

                          <button
                            style={styles.button}
                            to={`/${propsOfHotelId}/french/outlets`}
                            onClick={() => handleClick("french")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-france.png?alt=media&token=258b78c2-b996-4a70-a1da-bdd546a2014f"
                              height="100"
                              alt=""
                            />
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {hotel.twoLanguages === true ? (
                        <div style={styles.buttons2}>
                          {propsOfHotelId === "1HNJiY6t2xBw5c3zjV3R" ? (
                            <button
                              style={styles.button2}
                              to={`/${propsOfHotelId}/spanish/2812/menu`}
                              onClick={() => handleClick("spanish")}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-colombia.png?alt=media&token=079ecd38-ebce-48b3-abfa-0a503b7df197"
                                height="100"
                                alt=""
                              />
                            </button>
                          ) : (
                            <>
                              {propsOfHotelId === "fNIFexhS3r0NsJl5qRhn" ? (
                                <button
                                  style={styles.button2}
                                  to={`/${propsOfHotelId}/spanish/2812/menu`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-argentina.png?alt=media&token=13fb0249-52c9-4f57-97e7-00c4aec2a2e1"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              ) : (
                                <button
                                  style={styles.button2}
                                  to={`/${propsOfHotelId}/spanish/2812/menu`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-espan%CC%83a.png?alt=media&token=d1331b88-5303-411e-bd66-a769488ec2dc"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              )}
                            </>
                          )}

                          <button
                            style={styles.button2}
                            to={`/${propsOfHotelId}/english/2812/menu`}
                            onClick={() => handleClick("english")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-usa.png?alt=media&token=e6526e23-7e5b-49a7-91db-fb082c672b23"
                              height="100"
                              alt=""
                            />
                          </button>

                          {/* <Link style={styles.button} to="/forms">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Frealizar-checkout-black.png?alt=media&token=24a9e6f4-50e3-45ad-9e30-405724d2776b"
            style={{}}
            height="100"
            alt=""
          />
        </Link> */}
                        </div>
                      ) : (
                        <div style={styles.buttons}>
                          {propsOfHotelId === "1HNJiY6t2xBw5c3zjV3R" ? (
                            <button
                              style={styles.button}
                              to={`/${propsOfHotelId}/spanish/2812/menu`}
                              onClick={() => handleClick("spanish")}
                            >
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-colombia.png?alt=media&token=079ecd38-ebce-48b3-abfa-0a503b7df197"
                                height="100"
                                alt=""
                              />
                            </button>
                          ) : (
                            <>
                              {propsOfHotelId === "fNIFexhS3r0NsJl5qRhn" ? (
                                <button
                                  style={styles.button}
                                  to={`/${propsOfHotelId}/spanish/2812/menu`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-argentina.png?alt=media&token=13fb0249-52c9-4f57-97e7-00c4aec2a2e1"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              ) : (
                                <button
                                  style={styles.button}
                                  to={`/${propsOfHotelId}/spanish/2812/menu`}
                                  onClick={() => handleClick("spanish")}
                                >
                                  <img
                                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-espan%CC%83a.png?alt=media&token=d1331b88-5303-411e-bd66-a769488ec2dc"
                                    height="100"
                                    alt=""
                                  />
                                </button>
                              )}
                            </>
                          )}

                          <button
                            style={styles.button}
                            to={`/${propsOfHotelId}/english/2812/menu`}
                            onClick={() => handleClick("english")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-usa.png?alt=media&token=e6526e23-7e5b-49a7-91db-fb082c672b23"
                              height="100"
                              alt=""
                            />
                          </button>

                          <button
                            style={styles.button}
                            to={`/${propsOfHotelId}/portuguese/2812/menu`}
                            onClick={() => handleClick("portuguese")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-brasil.png?alt=media&token=4cd17411-a6cc-4c07-bf53-d3d7264d51ce"
                              height="100"
                              alt=""
                            />
                          </button>

                          <button
                            style={styles.button}
                            to={`/${propsOfHotelId}/french/2812/menu`}
                            onClick={() => handleClick("french")}
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fflags%2Fflag-france.png?alt=media&token=258b78c2-b996-4a70-a1da-bdd546a2014f"
                              height="100"
                              alt=""
                            />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
