import React from "react";
import firebase from "../../../services/firebase";
import Home from "../../../client/components/views/Home";
import { connect } from "react-redux";
import {
  saveHotel,
  saveHotelId,
  saveLanguage,
} from "../../../store/actions/hotelAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
//import { hideLoader, showLoader } from "../../../store/actions/loginAction";
import FullPageLoader from "../../../restaurant/components/FullPageLoader/FullPageLoader";

const DB = firebase.db;
const MySwal = withReactContent(Swal);

//let roomsOfHotel;
let hotelInfo;
let control;
//let controlPay;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveHotel: (hotel) => dispatch(saveHotel(hotel)),
    saveHotelId: (HotelId) => dispatch(saveHotelId(HotelId)),
    saveLanguage: (HotelId) => dispatch(saveLanguage(HotelId)),
  };
};

class ClientViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: {},
      hotel: {},
      loader: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    //CUANDO SE INICIA EL COMPONENTE:
    let HotelId = this.props.match.params.idHotel;

    if (HotelId === "1HNJiY6t2xBw5c3zjV3R") {
      window.location.href = `https://www.lovable-hotels.com/`;
    }

    this.props.saveHotelId(HotelId);
    //0. preguntar primero por algo para verificar que ya cargo el state.
    // if (this.props.hotelId) {
    // if (this.props.hotelInfoRedux.name) {
    //console.log("llegue despues");
    //1. SI TIENE STATE HOTEL, SOLO MANDA AL STATE LOCAL LA INFO ESA.
    //   this.setState({ hotel: this.props.hotelInfoRedux, loader: false });
    //  } else {
    //console.log("llegue primero");
    //2. SI NO LA VA A BUSCAR Y LA ENVIA AL STATE DE REDUX.
    hotelInfo = DB.collection("hoteles").doc(
      `${this.props.match.params.idHotel}`
    );
    hotelInfo.get().then((doc) => {
      this.props.saveHotel(doc.data());
      this.setState({ hotel: doc.data(), loader: false });
    });
    // }
    // }

    // hotelInfo.onSnapshot((docSnapshot) => {
    //   this.setState({ hotel: docSnapshot.data(), loader: false });
    // });

    // this.props.dispatch(showLoader());
    // roomsOfHotel = DB.collection("hoteles")
    //   .doc(`${this.props.match.params.idHotel}`)
    //   .collection("rooms");

    //MEJORAR CON UN WHERE
    // roomsOfHotel.onSnapshot((docSnapshot) => {
    //   docSnapshot.forEach((doc) => {
    //     if (doc.id === this.props.match.params.idRoom) {
    //       this.setState({
    //         room: {
    //           clientActual: doc.data().clientActual,
    //           number: doc.data().number,
    //           orderActual: doc.data().orderActual,
    //           secretCode: doc.data().secretCode,
    //           state: doc.data().state,
    //           clean: doc.data().clean,
    //           pay: doc.data().pay,
    //           orderStatus: doc.data().orderStatus,
    //           noDisturb: doc.data().noDisturb,
    //           appInService: doc.data().appInService,
    //           id: doc.id,
    //         },
    //       });
    //     }
    //   });
    // });

    // setTimeout(() => {
    //   this.props.dispatch(hideLoader());
    // }, 500);

    //REVISAR ESTO SI NO ES REPETITIVO
    // controlPay = DB.collection("hoteles")
    //   .doc(this.props.match.params.idHotel)
    //   .collection("rooms")
    //   .doc(this.props.match.params.idRoom);
    // controlPay.get().then((data) => (control = data.data().pay));
  }

  handleClick(type) {

      if(this.state.hotel.solutionHotelFull===true){


        if(this.props.match.params.idHotel === "EWAhAiiIZ4ERD7caBlkR"){ this.props.history.push(
          `/${this.props.match.params.idHotel}/${type}/BVntwydWW1kpJBtr1Cqe`
        );}else{ this.props.history.push(
          `/${this.props.match.params.idHotel}/${type}/login`
        );}


       

      } else{


        if(this.state.hotel.outlets===true){
          this.props.history.push(
            `/${this.props.match.params.idHotel}/${type}/outlets`
          );
        }else{

          this.props.history.push(
            `/${this.props.match.params.idHotel}/${type}/2812/menu`
          );
        }




       



          //ver si es outlet o menu



      }







    if (
      type === "spanish" ||
      type === "english" ||
      type === "portuguese" ||
      type === "french"
    ) {
      this.props.saveLanguage(type);
    }

    let roomActual = DB.collection("hoteles").doc(
      `${this.props.match.params.idHotel}/rooms/${this.props.match.params.idRoom}`
    );

    if (type === "clean") {
      this.state.room.clean === false
        ? roomActual.update({ clean: true })
        : roomActual.update({ clean: false });
    }

    if (type === "doNotDisturb") {
      this.state.room.noDisturb === false
        ? roomActual.update({ noDisturb: true })
        : roomActual.update({ noDisturb: false });
    }

    if (!control && type === "payment") {
      MySwal.fire({
        title: "Are you sure you want to request bill?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2EC4B6",
        cancelButtonColor: "#ff2068",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.value) {
          MySwal.fire({
            title: "Success!",
            text: "Your bill has been to request.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ff2068",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
          });
          if (this.state.room.pay === false) {
            roomActual.update({ pay: true });
            control = true;
            this.props.history.push(
              `/${this.props.match.params.idHotel}/${this.props.match.params.idRoom}/mail`
            );
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        <Home
          handleClick={this.handleClick}
          room={this.state.room}
          hotel={this.state.hotel}
          propsOfHotelId={this.props.match.params.idHotel}
          propsOfRoomId={this.props.match.params.idRoom}
          loader={this.state.loader}
          whatsapp={this.state.hotel.whatsappNumber}
        />
        <div>
          <FullPageLoader />
        </div>
      </div>
    );
  }
}

//export default ClientViewContainer;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientViewContainer);
