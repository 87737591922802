import React from "react";
import Menu from "../../../client/components/views/Categories";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import {
  saveMenues,
  saveProducts,
  saveCategories,
  saveCategoriesHistory,
  saveCategoriesBackup,
} from "../../../store/actions/menuClientActions";
const DB = firebase.db;

//let idOrder;
//let idProd;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    hotelInfo: state.hotel.hotelInfo,
    language: state.hotel.language,
    menuesHotel: state.menu.menues,
    categoriesHotel: state.menu.categories,
    categoriesHotelBackup: state.menu.categoriesBackup,
    productsHotel: state.menu.products,
    categoriesHistoryHotel: state.menu.categoriesHistory,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveMenues: (menues) => dispatch(saveMenues(menues)),
    saveProducts: (products) => dispatch(saveProducts(products)),
    saveCategories: (categories) => dispatch(saveCategories(categories)),
    saveCategoriesBackup: (categories) =>
      dispatch(saveCategoriesBackup(categories)),
    saveCategoriesHistory: (categories) =>
      dispatch(saveCategoriesHistory(categories)),
  };
};

class MenuContainerCliente extends React.Component {
  constructor(props) {
    super();
    this.state = {
      categorias: [],
      producto: [],

      categoriasBackup: [],
      //numOrder: 0,
      //prod: [],
      loader: true,
      menuSelectedName: "",
      menuSelectedHours: "",
      menuSelectedId: "",

      menuSelectedNameSpanish: "",
      menuSelectedNameEnglish: "",
      menuSelectedNamePortuguese: "",
      menuSelectedNameFrench: "",

      aclarationSpanish: "",
      aclarationEnglish: "",
      aclarationPortuguese: "",
      aclarationFrench: "",

      language: "",
      hotel: {},

      selectedPDF: "",
      popUp: false,
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount() {
    // if (this.props.hotelId) {
    //seteo language

    if (!this.props.hotelId) {
      this.setState({
        hotel: { primaryColor: "#339AE7" },
        language: this.props.match.params.idLanguage,
      });
    } else {
      this.setState({
        hotel: this.props.hotelInfo,
        language: this.props.match.params.idLanguage,
      });
    }

    if (this.props.menuesHotel.length) {
      let nameCategorySelected;
      let nameCategorySelectedSpanish;
      let nameCategorySelectedEnglish;
      let nameCategorySelectedPortuguese;
      let nameCategorySelectedFrench;
      let hoursCategorySelected;
      let idCategorySelected;
      let aclarationSpanishData;
      let aclarationEnglishData;
      let aclarationPortugueseData;
      let aclarationFrenchData;

      let menuesRedux = this.props.menuesHotel;

      menuesRedux.map((menu) => {
        if (menu.id === this.props.match.params.idMenu) {
          hoursCategorySelected = menu.hours;
          idCategorySelected = menu.id;
          nameCategorySelected = menu.name;
          nameCategorySelectedSpanish = menu.nameOfMenuSpanish;
          nameCategorySelectedEnglish = menu.nameOfMenuEnglish;
          nameCategorySelectedPortuguese = menu.nameOfMenuPortuguese;
          nameCategorySelectedFrench = menu.nameOfMenuFrench;

          aclarationSpanishData = menu.aclarationSpanish;
          aclarationEnglishData = menu.aclarationEnglish;
          aclarationPortugueseData = menu.aclarationPortuguese;
          aclarationFrenchData = menu.aclarationFrench;
        }
      });

      this.setState({
        menuSelectedHours: hoursCategorySelected,
        menuSelectedId: idCategorySelected,
        menuSelectedName: nameCategorySelected,

        menuSelectedNameSpanish: nameCategorySelectedSpanish,
        menuSelectedNameEnglish: nameCategorySelectedEnglish,
        menuSelectedNamePortuguese: nameCategorySelectedPortuguese,
        menuSelectedNameFrench: nameCategorySelectedFrench,

        aclarationSpanish: aclarationSpanishData,
        aclarationEnglish: aclarationEnglishData,
        aclarationPortuguese: aclarationPortugueseData,
        aclarationFrench: aclarationFrenchData,
      });
    } else {
      const menuDocs = DB.collection("hoteles")
        .doc(this.props.match.params.idHotel)
        .collection("menues")
        .doc(this.props.match.params.idMenu);

      menuDocs.get().then((menuDocument) => {
        let nameCategorySelected;

        let nameCategorySelectedSpanish;
        let nameCategorySelectedEnglish;
        let nameCategorySelectedPortuguese;
        let nameCategorySelectedFrench;

        let hoursCategorySelected;
        let idCategorySelected;

        let aclarationSpanishData;
        let aclarationEnglishData;
        let aclarationPortugueseData;
        let aclarationFrenchData;

        nameCategorySelected = menuDocument.data().nameOfMenu;

        nameCategorySelectedSpanish = menuDocument.data().nameOfMenuSpanish;
        nameCategorySelectedEnglish = menuDocument.data().nameOfMenuEnglish;
        nameCategorySelectedPortuguese = menuDocument.data()
          .nameOfMenuPortuguese;
        nameCategorySelectedFrench = menuDocument.data().nameOfMenuFrench;

        hoursCategorySelected = menuDocument.data().hours;
        idCategorySelected = menuDocument.id;

        aclarationSpanishData = menuDocument.data().aclarationSpanish;
        aclarationEnglishData = menuDocument.data().aclarationEnglish;
        aclarationPortugueseData = menuDocument.data().aclarationPortuguese;
        aclarationFrenchData = menuDocument.data().aclarationFrench;
        // console.log(aclarationSpanishData);
        this.setState({
          menuSelectedHours: hoursCategorySelected,
          menuSelectedId: idCategorySelected,
          menuSelectedName: nameCategorySelected,

          menuSelectedNameSpanish: nameCategorySelectedSpanish,
          menuSelectedNameEnglish: nameCategorySelectedEnglish,
          menuSelectedNamePortuguese: nameCategorySelectedPortuguese,
          menuSelectedNameFrench: nameCategorySelectedFrench,

          aclarationSpanish: aclarationSpanishData,
          aclarationEnglish: aclarationEnglishData,
          aclarationPortuguese: aclarationPortugueseData,
          aclarationFrench: aclarationFrenchData,

          loader: false,
        });
      });
    }

    let arrayHistory = this.props.categoriesHistoryHotel;
    let checkCategoriesHistory = arrayHistory.includes(
      this.props.match.params.idMenu
    );

    if (this.props.categoriesHotel.length && checkCategoriesHistory) {
      let arrayCategoriesRedux = [];
      let arrayCategoriesReduxBackup = [];

      let categoriesRedux = this.props.categoriesHotel;
      let categoriesReduxBackup = this.props.categoriesHotelBackup;

      categoriesRedux.map((categorie) => {
        if (categorie.menuId === this.props.match.params.idMenu) {
          arrayCategoriesRedux.push({
            nameCategoria: categorie.nameCategoria,
            nameCategoriaSpanish: categorie.nameCategoriaSpanish,
            nameCategoriaEnglish: categorie.nameCategoriaEnglish,
            nameCategoriaPortuguese: categorie.nameCategoriaPortuguese,
            nameCategoriaFrench: categorie.nameCategoriaFrench,
            menuId: this.props.match.params.idMenu,
            products: categorie.products,
            withImages: categorie.withImages,
            imageCategory: categorie.imageCategory,

            id: categorie.id,
          });
        }
      });

      categoriesReduxBackup.map((categorie) => {
        if (categorie.menuId === this.props.match.params.idMenu) {
          arrayCategoriesReduxBackup.push({
            nameCategoria: categorie.nameCategoria,
            nameCategoriaSpanish: categorie.nameCategoriaSpanish,
            nameCategoriaEnglish: categorie.nameCategoriaEnglish,
            nameCategoriaPortuguese: categorie.nameCategoriaPortuguese,
            nameCategoriaFrench: categorie.nameCategoriaFrench,
            menuId: this.props.match.params.idMenu,
            products: categorie.products,
            withImages: categorie.withImages,
            imageCategory: categorie.imageCategory,
            id: categorie.id,
          });
        }
      });

      this.setState({
        categorias: arrayCategoriesRedux,
        categoriasBackup: arrayCategoriesReduxBackup,
        loader: false,
      });
    } else {
      let arrayCategories = [];
      let arrayCategoriesHistory = [];
      let arrayProducts = [];
      let arrayCategoriasBackup = [];

      const categoriesDocs = DB.collection("hoteles")
        .doc(this.props.match.params.idHotel)
        .collection("menues")
        .doc(this.props.match.params.idMenu)
        .collection("categories")
        .orderBy("orderCategory", "asc");

      categoriesDocs.get().then((categories) => {
        categories.forEach((categ) => {
          //ACA ORDENA LOS PRODUCTOS
          let productsWithOrder = categ.data().products;
          let ordenar = function (arr) {
            arr.sort(function (a, b) {
              return a.orderProduct - b.orderProduct;
            });
          };

          let ejecutandoFuntion = ordenar(productsWithOrder);

          //HASTA ACA


          if (categ.data().active !== false) {

            this.props.saveCategoriesBackup({
              id: categ.id,
              nameCategoria: categ.data().name,
              nameCategoriaSpanish: categ.data().nameSpanish,
              nameCategoriaEnglish: categ.data().nameEnglish,
              nameCategoriaPortuguese: categ.data().namePortuguese,
              nameCategoriaFrench: categ.data().nameFrench,
              products: categ.data().products,
              withImages: categ.data().withImages,
              menuId: this.props.match.params.idMenu,
              imageCategory: categ.data().imageCategory,
            });
            arrayCategoriasBackup.push({
              id: categ.id,
              nameCategoria: categ.data().name,
              nameCategoriaSpanish: categ.data().nameSpanish,
              nameCategoriaEnglish: categ.data().nameEnglish,
              nameCategoriaPortuguese: categ.data().namePortuguese,
              nameCategoriaFrench: categ.data().nameFrench,
              products: categ.data().products,
              withImages: categ.data().withImages,
              menuId: this.props.match.params.idMenu,
              imageCategory: categ.data().imageCategory,
            });

            this.props.saveCategories({
              id: categ.id,
              nameCategoria: categ.data().name,
              nameCategoriaSpanish: categ.data().nameSpanish,
              nameCategoriaEnglish: categ.data().nameEnglish,
              nameCategoriaPortuguese: categ.data().namePortuguese,
              nameCategoriaFrench: categ.data().nameFrench,
              products: productsWithOrder,
              withImages: categ.data().withImages,
              imageCategory: categ.data().imageCategory,
              menuId: this.props.match.params.idMenu,
            });
            arrayCategories.push({
              id: categ.id,
              nameCategoria: categ.data().name,
              nameCategoriaSpanish: categ.data().nameSpanish,
              nameCategoriaEnglish: categ.data().nameEnglish,
              nameCategoriaPortuguese: categ.data().namePortuguese,
              nameCategoriaFrench: categ.data().nameFrench,
              products: productsWithOrder,
              withImages: categ.data().withImages,
              imageCategory: categ.data().imageCategory,
              menuId: this.props.match.params.idMenu,
            });
            this.props.saveCategoriesHistory(this.props.match.params.idMenu);
          }


        });

        this.setState({
          categorias: arrayCategories,
          loader: false,
          categoriasBackup: arrayCategoriasBackup,
        });
      });
    }

    // const menuDocs = DB.collection("hoteles")
    //   .doc(this.props.match.params.idHotel)
    //   .collection("menues")
    //   .doc(this.props.match.params.idMenu);

    // menuDocs.get().then((menuDocument) => {
    //   let nameCategorySelected;

    //   let nameCategorySelectedSpanish;
    //   let nameCategorySelectedEnglish;
    //   let nameCategorySelectedPortuguese;
    //   let nameCategorySelectedFrench;

    //   let hoursCategorySelected;

    //   let aclarationSpanishData;
    //   let aclarationEnglishData;
    //   let aclarationPortugueseData;
    //   let aclarationFrenchData;

    //   nameCategorySelected = menuDocument.data().nameOfMenu;

    //   nameCategorySelectedSpanish = menuDocument.data().nameOfMenuSpanish;
    //   nameCategorySelectedEnglish = menuDocument.data().nameOfMenuEnglish;
    //   nameCategorySelectedPortuguese = menuDocument.data()
    //     .nameOfMenuPortuguese;
    //   nameCategorySelectedFrench = menuDocument.data().nameOfMenuFrench;

    //   hoursCategorySelected = menuDocument.data().hours;

    //   aclarationSpanishData = menuDocument.data().aclarationSpanish;
    //   aclarationEnglishData = menuDocument.data().aclarationEnglish;
    //   aclarationPortugueseData = menuDocument.data().aclarationPortuguese;
    //   aclarationFrenchData = menuDocument.data().aclarationFrench;
    //   // console.log(aclarationSpanishData);
    //   this.setState({
    //     menuSelectedHours: hoursCategorySelected,
    //     menuSelectedName: nameCategorySelected,

    //     menuSelectedNameSpanish: nameCategorySelectedSpanish,
    //     menuSelectedNameEnglish: nameCategorySelectedEnglish,
    //     menuSelectedNamePortuguese: nameCategorySelectedPortuguese,
    //     menuSelectedNameFrench: nameCategorySelectedFrench,

    //     aclarationSpanish: aclarationSpanishData,
    //     aclarationEnglish: aclarationEnglishData,
    //     aclarationPortuguese: aclarationPortugueseData,
    //     aclarationFrench: aclarationFrenchData,

    //     categorias: arrayCategories,
    //     loader: false,
    //   });
    // });

    //   }
    //}
    // idOrder = DB.collection("hoteles")
    //   .doc(this.props.match.params.idHotel)
    //   .collection("rooms")
    //   .doc(this.props.match.params.idRoom);
    // idOrder
    //   .get()
    //   .then((data) => {
    //     this.setState({ numOrder: data.data().orderActual });
    //   })
    //   .then(() => {
    //     idProd = DB.collection("hoteles")
    //       .doc(this.props.match.params.idHotel)
    //       .collection("orders")
    //       .doc(`${this.state.numOrder}`)
    //       .collection("products");
    //     idProd.get().then((dataOrder) => {
    //       dataOrder.forEach((data) => {
    //         this.setState({
    //           prod: [...this.state.prod, { prod: data.data() }],
    //         });
    //       });
    //     });
    //   });
  }

  handleEdit(e, id, categId, name, price) {
    e.preventDefault();

    let categoriesWithOrderBackup = this.state.categoriasBackup;

    let foundCategory = categoriesWithOrderBackup.find(
      (element) => element.id === categId
    );

    //aca comparar y generar el nuevo id.
    let productsWithoutOrderBackup = foundCategory.products;

    let found = productsWithoutOrderBackup.findIndex(
      (element) => element.name === name && element.price === price
    );
    //

    this.props.history.push(
      `/${this.props.match.params.idHotel}/${this.state.language}/${this.props.match.params.idMenu}/${categId}/${found}/client`
    );
  }

  handleClick(e, categImage) {
    e.preventDefault();
    if (categImage === "close") {
      this.setState({ selectedPDF: "", popUp: false });
    } else {
      this.setState({ selectedPDF: categImage, popUp: true });
    }
  }

  handleGoBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <Menu
          idRoom={this.props.match.params.idRoom}
          idHotel={this.props.match.params.idHotel}
          idMenu={this.props.match.params.idMenu}
          language={this.state.language}
          categoria={this.state.categorias}
          productos={this.state.producto}
          numOrder={this.state.numOrder}
          prod={this.state.prod}
          loader={this.state.loader}
          menuSelectedName={this.state.menuSelectedName}
          menuSelectedNameSpanish={this.state.menuSelectedNameSpanish}
          menuSelectedNameEnglish={this.state.menuSelectedNameEnglish}
          menuSelectedNamePortuguese={this.state.menuSelectedNamePortuguese}
          menuSelectedNameFrench={this.state.menuSelectedNameFrench}
          menuSelectedHours={this.state.menuSelectedHours}
          menuSelectedId={this.state.menuSelectedId}
          aclarationSpanish={this.state.aclarationSpanish}
          aclarationEnglish={this.state.aclarationEnglish}
          aclarationPortuguese={this.state.aclarationPortuguese}
          aclarationFrench={this.state.aclarationFrench}
          hotel={this.state.hotel}
          whatsapp={this.state.hotel.whatsappNumber}
          handleEdit={this.handleEdit}
          handleClick={this.handleClick}
          handleGoBack={this.handleGoBack}
          state={this.state}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuContainerCliente);
