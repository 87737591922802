import React from "react";
import { withRouter } from "react-router-dom";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import { saveLoginClient } from "../../../store/actions/loginClientAction";
import Login from "../../../client/components/views/Login";

const DB = firebase.db;
let ClientActualApp = 0;

class ClientLoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfRoom: "",
      code: "",
    };
    this.handlerChange = this.handlerChange.bind(this);
    this.handlerSubmit = this.handlerSubmit.bind(this);
  }

  handlerChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  handlerSubmit(e) {
    e.preventDefault();
    //Guardamos en la variable el hotelId que viene por URL
    let HotelId = this.props.match.params.idHotel;
    let Language = this.props.match.params.language;
    //Aca crea la variable para buscar todas las rooms y busca la ingresada
    
    
    //let RoomsHotel = DB.collection("hoteles").doc(HotelId).collection("rooms").where('number', '==', this.state.numberOfRoom)
    let RoomsHotel = DB.collection("hoteles").doc(HotelId).collection("rooms").where('number', '==',this.state.numberOfRoom )


    RoomsHotel.get().then((result) => {

      //console.log("resultado",result)}
    
    
 // let RoomsHotel = DB.collection("hoteles").doc(HotelId).collection("rooms")
//RoomsHotel.get().then((result) => {
      result.forEach((doc) => {
        //Aca comparamos si el numero de mesa y el codigo secreto son correctos.
       // console.log("llegue")
        console.log("llegue",doc.data())

        if (
          doc.data().secretCode == this.state.code
        ) {


          this.props.history.push(`/${HotelId}/${Language}/${doc.id}`);






          // //Aca asigna el RoomId a una variable para usarla despues.
          // let RoomIdActual = doc.id;
          // // Va a la base de datos y pregunta si en la Room hay numero actual de cliente. Si no hay, crea y lo asigna.
          // // Si ya hay uno asignado, lo que hace es enviar al Store de Redux el client de la DB.
          // if (doc.data().clientActual !== 0) {
          //   ClientActualApp = doc.data().clientActual;
          //   const client = { HotelId, RoomIdActual, ClientActualApp };
          //   this.props.saveLoginClient(client);
          //   //Le agrega a la URL la room ingresada si es correcto.
          //   this.props.history.push(`/${HotelId}/${doc.id}`);
          // } else {
          //   //En esta parte, entramos al hotel actual y buscamos el ultimo numero preparado para cliente. Le asignamos ese numero a la room actual. Y le sumamos 1 a ese indicador para dejarlo listo para el proximo cliente.
          //   let clientTotal;
          //   //let arr = [];
          //   const hotelDoc = DB.collection("hoteles").doc(HotelId);
          //   hotelDoc.get().then((result) => {
          //     let res = result.data();
          //     //Esta variable toma el valor preparado para el proximo cliente
          //     clientTotal = res.clientTotalNumber;
          //     //Aca asignamos ese numero de cliente actual a la mesa actual.
          //     hotelDoc.update({ clientTotalNumber: clientTotal + 1 });
          //     //Aca buscamos otra vez todas las mesas con el numero de room ingresado en el form y le asignamos el numero de cliente.
          //     ClientActualApp = clientTotal;
          //     const client = { HotelId, RoomIdActual, ClientActualApp };
          //     this.props.saveLoginClient(client);
          //     //ACA SE PUEDE MEJORAR PARA QUE SOLO VAYA A BUSCAR UNA
          //     RoomsHotel.get().then((result) => {
          //       result.forEach((doc) => {
          //         if (doc.data().number == this.state.numberOfRoom) {
          //           //En esta variable creamos la ruta para actualizar la mesa actual.
          //           const RoomActual = DB.collection("hoteles")
          //             .doc(HotelId)
          //             .collection("rooms")
          //             .doc(RoomIdActual);
          //           //Actualizamos en la mesa actual el numero de cliente.
          //           RoomActual.update({ clientActual: clientTotal });
          //         }
          //       });
          //     });
          //   });
          //   //Le agrega a la URL la mesa ingresada si es correcto.
          //   this.props.history.push(`/${HotelId}/${doc.id}`);
          // }



        }
      });
    }

    

    


    );
  }

  render() {
    return (
      <div>
        <Login
          handleClick={this.handleClick}
          handlerChange={this.handlerChange}
          handlerSubmit={this.handlerSubmit}
          language={this.props.match.params.language}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveLoginClient: (client) => dispatch(saveLoginClient(client)),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(ClientLoginContainer)
);
