import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  containerFlex: {
    marginLeft: "250px",
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  titleAnalytic: {
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "35px",
  },
  titleDashboard: {
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "25px",
  },
  descriptionAnalytic: {
    color: "#000",
    textAlign: "center",
    fontWeight: "400",
    marginBottom: "0px",
  },
  shadow: {
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
};

export default ({
  hotel,
  rol,
  name,
  loader,
  hotelTotalMenues,
  hotelTotalCategories,
  hotelTotalProducts,
  hotelTotalDisableProducts,
  hoteles,
}) => {
  let productsWithStock = hotelTotalProducts - hotelTotalDisableProducts;
  //console.log(hoteles);
  return (
    <>
      {rol === "developer" ? (
        <div style={styles.container}>
          <h1 className="font-weight-bold">Dashboard</h1>
          <Link
            style={{
              textDecoration: "none",
              color: "#ffffff",
              backgroundColor: "#ff2068",
              borderColor: "#ff2068",
              marginTop: "20px",
              marginBottom: "10px",
            }}
            className="btn btn-primary"
            to="/create/hotel"
          >
            Create Hotel
          </Link>
          <hr />

          <div
            className="row row-cols-1 row-cols-md-4"
            style={{
              marginTop: "30px",
            }}
          >
            {hoteles.length
              ? hoteles.map((hotel) => {
                  return (
                    <div class="col mb-4">
                      <div className="card h-100" style={{ width: "18rem" }}>
                        <img
                          src={hotel.logoImage}
                          class="card-img-top"
                          alt="..."
                          style={{
                            padding: "30px",
                            backgroundColor: "#ff2068",
                          }}
                        />
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={styles.titleDashboard}
                          >
                            {hotel.name}
                          </h5>
                          {/* <p className="card-text">
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </p> */}
                        </div>

                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            Menús: {hotel.totalMenues}
                          </li>
                          <li className="list-group-item">
                            Categorías: {hotel.totalCategories}
                          </li>
                          <li className="list-group-item">
                            Productos: {hotel.totalProducts}
                          </li>
                          <li className="list-group-item">
                            Sin stock: {hotel.productsWithoutStock}
                          </li>
                        </ul>

                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">{hotel.titleApp}</li>
                          <li className="list-group-item">
                            {hotel.subtitleApp}
                          </li>
                          <li className="list-group-item">{hotel.infoApp}</li>
                        </ul>

                        <ul className="list-group list-group-flush">
                          {hotel.twoLanguages ? (
                            <li className="list-group-item">2 Idiomas</li>
                          ) : (
                            <li className="list-group-item">4 Idiomas</li>
                          )}
                        </ul>

                        {hotel.solutionMenu ? (
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">Solución Menús</li>
                          </ul>
                        ) : (
                          <></>
                        )}

                        {/* <div className="card-body">
                          <a href="#" className="card-link">
                            Card link
                          </a>
                          <a href="#" className="card-link">
                            Another link
                          </a>
                        </div> */}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : (
        <>
          {loader == false ? (
            <div style={styles.container}>
              <h1 className="font-weight-bold">{`Hola ${name}!`}</h1>
              <h6 className="font-weight-light">{`Esperamos que tengas un buen día.`}</h6>

              <hr />

              {hotel.dashboardMenu ? (
                <div
                  className="row row-cols-1 row-cols-md-3"
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <div className="col mb-4">
                    <div className="card h-100" style={styles.shadow}>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdashboard-menues.png?alt=media&token=9e7fad6c-0841-4255-8933-d29ddd3bc9e1"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 style={styles.titleAnalytic}>{hotelTotalMenues}</h5>

                        <p style={styles.descriptionAnalytic}>Total Menues</p>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-4">
                    <div className="card h-100" style={styles.shadow}>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdashboard-menues.png?alt=media&token=9e7fad6c-0841-4255-8933-d29ddd3bc9e1"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 style={styles.titleAnalytic}>
                          {hotelTotalCategories}
                        </h5>
                        <p style={styles.descriptionAnalytic}>
                          Total Categories
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col mb-4">
                    <div className="card h-100" style={styles.shadow}>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdashboard-menues.png?alt=media&token=9e7fad6c-0841-4255-8933-d29ddd3bc9e1"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 style={styles.titleAnalytic}>
                          {hotelTotalProducts}
                        </h5>
                        <p style={styles.descriptionAnalytic}>Total Products</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col mb-4">
                    <div className="card h-100" style={styles.shadow}>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdashboard-check.png?alt=media&token=c2859e63-714a-4d2c-bf39-2bc747955533"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 style={styles.titleAnalytic}>
                          {productsWithStock}
                        </h5>
                        <p style={styles.descriptionAnalytic}>
                          Products with Stock
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col mb-4">
                    <div className="card h-100" style={styles.shadow}>
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdashboard-no.png?alt=media&token=929241d1-45de-40ad-85b0-6237961d231b"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 style={styles.titleAnalytic}>
                          {hotelTotalDisableProducts}
                        </h5>
                        <p style={styles.descriptionAnalytic}>
                          Products without Stock
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div style={styles.containerFlex}>
              <Loader
                type="TailSpin"
                color="#ff2068"
                height={100}
                width={100}
                timeout={10000} //3 secs
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
