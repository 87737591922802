import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
// "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-app.png?alt=media&token=18234069-f4d0-46c7-b98e-6978e6ef68e3";

const styles = {
  container: {},
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  containerInService: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  titleInService: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    //margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,
  },

  top: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    marginLeft: "30px",
    //  backgroundColor: "green",
  },
  topCenter: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },

  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    alignItems: "center",
    // marginLeft: "30px",
    //  backgroundColor: "green",
  },
  notifications: {
    flex: 1.5,
    display: "flex",
    justifyContent: "center",
    //flexFlow: "column",
    alignItems: "center",
    backgroundColor: "black",
    borderRadius: "40px 40px 0 0",
    paddingBottom: "10px",
  },
  notificationsActive: {
    flex: 1.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //flexFlow: "column",
    backgroundColor: "#ff2068",
    borderRadius: "40px 40px 0 0",
    paddingBottom: "10px",

  },

  marketingTitle: {
    margin: 0,
    marginTop: "10px",
    //fontSize: 25,
    fontWeight: 400,

    textAlign: "center",
    color: "#ffffff",

    fontSize: 55,
    fontFamily: "SimpleJoys",
    // backgroundColor: "#ff2068",
  },

  notificationsTitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,

    textAlign: "center",
    color: "#ffffff",

    //fontSize: 65,
    //fontFamily: "LoewMedium",
    // backgroundColor: "#ff2068",
  },

  hotelInfoSupport: {
    flex: 1.5,
    display: "flex",
    // justifyContent: "center",
    // flexFlow: "column",
    // alignItems: "center",
    backgroundColor: "black",
  },
  hotelInfoSupportActive: {
    flex: 1.5,
    display: "flex",
    backgroundColor: "#ff2068",
  },
  hotelInfo: {
    flex: 12,
    display: "flex",
    justifyContent: "space-around",
    //flexFlow: "column",
    alignItems: "center",
    //backgroundColor: "grey",
    backgroundColor: "white",
    borderRadius: "40px 40px 0 0",
  },
  logo: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    // flexFlow: "column",
    // height: "180px",
    //widht: "auto",
    alignItems: "center",
    // backgroundColor: "#ff2068",
    // paddingTop: "12px",
    // paddingBottom: "12px",
    // borderRadius: "50px",
  },
  buttons: {
    flex: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  buttons2: {
    flex: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    backgroundColor: "white",
    paddingTop: "10px",
  },

  buttonsBlack: {
    flex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    //margin: "0 30px 0 30px",
    // backgroundColor: "white",
    // paddingTop: "10px",
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "5%",
    paddingBottom: "5%",
    // paddingTop: "22px",
    // paddingBottom: "22px",

    width: "44.5vw",

    backgroundColor: "#E2E6EF",
    // backgroundColor: "white",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    border:"none",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  button2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "10%",
    paddingBottom: "10%",

    // paddingTop: "22px",
    //  paddingBottom: "22px",
    //paddingTop: "65px",
    // paddingBottom: "65px",
    width: "44.5vw",

    backgroundColor: "#E2E6EF",
    // backgroundColor: "white",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  buttonBlack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "6%",
    paddingBottom: "6%",

    // paddingTop: "22px",
    //  paddingBottom: "22px",
    //paddingTop: "65px",
    // paddingBottom: "65px",
    width: "44.5vw",

    // backgroundColor: "#E2E6EF",
    // backgroundColor: "rgba(230,230,230,0.8)",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },


  buttonActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "45px",
    paddingTop: "5%",
    paddingBottom: "5%",
    // paddingTop: "22px",
    // paddingBottom: "22px",

    width: "44.5vw",

    backgroundColor: "#ff2068",
    // backgroundColor: "white",
    borderRadius: "25px",
    margin: "5px",
    marginTop: "0px",
    marginBottom: "10px",
    border:"none",
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },

  roomTitle: {
    margin: 0,
    //  fontSize: 45,
    fontSize: 65,
    fontFamily: "SimpleJoys",
    fontWeight: "bold",
    textAlign: "center",
    color: "#ffffff",
    //fontFamily: "hiltonFont,Arial, sans-serif",
  },
  roomSubtitle: {
    margin: 0,
    marginTop: "10px",
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: "#ffffff",
    //fontSize: 65,
    fontFamily: "LoewMedium",
  },
  optionsTitle: {
    flex: 1,
    margin: 0,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
  buttonText: {
    flex: 1,
    margin: 0,
    fontSize: 15,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  roomNumber: {
    margin: 0,
    //fontFamily: "Lato",
    fontSize: 35,
    fontWeight: 900,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
};

export default ({
  handleClick,
  room,
  hotel,
  whatsapp,
  propsOfHotelId,
  propsOfRoomId,
  loader,
  language
}) => {
  return (
    <>
      {loader == false ? (
        <div>
          {hotel.appInService == true ? (
          
                <div style={styles.containerInService}>
                  <h1 style={styles.titleInService}>En mantenimiento 🏨</h1>
                </div>
         
          ) : (
         
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${hotel.backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div style={styles.top}>



                  {propsOfHotelId === "EWAhAiiIZ4ERD7caBlkR" && language==="english"? (   <h1 style={styles.roomTitle}>Welcome!</h1>
):(   <h1 style={styles.roomTitle}>{hotel.titleApp}</h1>
)}


                  








                  {language==="spanish"?(    <h6 style={styles.roomSubtitle}>Habitación {room.number}.</h6>):(    <h6 style={styles.roomSubtitle}>Room {room.number}.</h6>)}


                






                  </div>

                  {hotel.solutionWhatsapp === true ? (
                    <Link
                      style={styles.buttonFloat}
                      //to={() => (window.location.href = "http://domain.com")}
                    >
                      <img
                        onClick={() =>
                          (window.location.href = `https://wa.me/%2B${whatsapp}`)
                        }
                        src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                        height="60"
                        alt="whatsapp-icon"
                      />
                    </Link>
                  ) : (
                    <></>
                  )}

{language==="spanish"?( <>
                  {room.pay === true ||
                  room.noDisturb === true ||
                  room.orderStatus === "pending" ||
                  room.clean === true ? (
                    <div style={styles.notificationsActive}>
                      {room.pay === true ? (
                        <p style={styles.notificationsTitle}>
                          Checkout Solicitado
                        </p>
                      ) : (
                        <>
                          {room.noDisturb === true ? (
                            <p style={styles.notificationsTitle}>
                              No molestar Activado
                            </p>
                          ) : (
                            <>
                              {room.orderStatus === "pending" ? (
                                <p style={styles.notificationsTitle}>
                                  Pedido de comida Solicitado
                                </p>
                              ) : (
                                <>
                                  {room.clean === true ? (
                                    <p style={styles.notificationsTitle}>
                                      Limpieza Solicitada
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (

                    <div style={styles.notifications}>
                    <p style={styles.notificationsTitle}>{hotel.infoApp}</p>
                  </div>
                   



                  )}
                </>
):( <>
    {room.pay === true ||
    room.noDisturb === true ||
    room.orderStatus === "pending" ||
    room.clean === true ? (
      <div style={styles.notificationsActive}>
        {room.pay === true ? (
          <p style={styles.notificationsTitle}>
            Checkout Requested
          </p>
        ) : (
          <>
            {room.noDisturb === true ? (
              <p style={styles.notificationsTitle}>
                Do not disturb On

              </p>
            ) : (
              <>
                {room.orderStatus === "pending" ? (
                  <p style={styles.notificationsTitle}>
                    Food order Requested
                  </p>
                ) : (
                  <>
                    {room.clean === true ? (
                      <p style={styles.notificationsTitle}>
                        Cleaning Requested
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    ) : (






     




<>
                    {propsOfHotelId === "EWAhAiiIZ4ERD7caBlkR"? (   <div style={styles.notifications}>
                            <p style={styles.notificationsTitle}>Try our new menu!</p>
                          </div>
                        ):(    <div style={styles.notifications}>
                            <p style={styles.notificationsTitle}>{hotel.infoApp}</p>
                          </div>
                        )}
                        
                        </>



    )}
  </>
)}
                   

                    {room.pay === true ||
                  room.noDisturb === true ||
                  room.orderStatus === "pending" ||
                  room.clean === true ? ( <div style={styles.hotelInfoSupportActive}>
                    <div style={styles.hotelInfo}>
                      {/* <p style={styles.optionsTitle}>Hotel Info</p> */}

                      {hotel.solutionInformationHotel === true && hotel.webURL!== ""? (
                        <Link>
                          <img
                            onClick={() =>
                              (window.location.href = `${hotel.webURL}`)
                            }
                            src={hotel.logoImage}
                            height="60"
                            alt="Logo"
                          />
                        </Link>
                      ) : (
                        <div style={styles.logo}>
                          {/* <Link
              style={styles.optionsTitle}
              to={`/${propsOfHotelId}/${propsOfRoomId}/information`}
            > */}
                          <img src={hotel.logoImage} height="60" alt="Logo" />
                          {/* </Link> */}
                        </div>
                      )}
                    </div>
                  </div>):( <div style={styles.hotelInfoSupport}>
                    <div style={styles.hotelInfo}>
                      {/* <p style={styles.optionsTitle}>Hotel Info</p> */}

                      {hotel.solutionInformationHotel === true && hotel.webURL!== ""? (
                        <Link>
                          <img
                            onClick={() =>
                              (window.location.href = `${hotel.webURL}`)
                            }
                            src={hotel.logoImage}
                            height="60"
                            alt="Logo"
                          />
                        </Link>
                      ) : (
                        <div style={styles.logo}>
                          {/* <Link
              style={styles.optionsTitle}
              to={`/${propsOfHotelId}/${propsOfRoomId}/information`}
            > */}
                          <img src={hotel.logoImage} height="60" alt="Logo" />
                          {/* </Link> */}
                        </div>
                      )}
                    </div>
                  </div>)}
                 



      {/* <Link style={styles.button} to="/forms">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Frealizar-checkout-black.png?alt=media&token=24a9e6f4-50e3-45ad-9e30-405724d2776b"
          style={{}}
          height="100"
          alt=""
        />
      </Link>  */}





{language==="spanish"?(<>
        <div style={styles.buttons}>
                      


                          {room.noDisturb === true ? (
              <button
                style={styles.buttonActive}
                onClick={() => handleClick("doNotDisturb")}
               // to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fno-molestar-white.png?alt=media&token=9dab1012-93ab-4bdb-969c-2540582a8d55"
                  height="100"
                  alt=""
                />
              </button>
            ) : (
              <button
                style={styles.button}
                onClick={() => handleClick("doNotDisturb")}
//to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fno-molestar-black.png?alt=media&token=ea93948d-601e-4782-a084-c470a26e6d70"
                  height="100"
                  alt=""
                />
              </button>
            )}


{room.clean === true ? (
              <button
                style={styles.buttonActive}
                onClick={() => handleClick("clean")}
             //   to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Flimpieza-habitacion-white.png?alt=media&token=889dfdb4-9acc-4541-b3de-8a4fb01ecf07"
                  height="100"
                  alt=""
                />
              </button>
            ) : (
              <button
                style={styles.button}
                onClick={() => handleClick("clean")}
            //    to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Flimpieza-habitacion-black.png?alt=media&token=b06e0ac3-0a86-4a5d-a2ff-73a9b963ffd8"
                  height="100"
                  alt=""
                />
              </button>
            )}


<Link
              style={styles.button}
              to={`/${propsOfHotelId}/${language}/2812/menu`}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fpedir-comida-black.png?alt=media&token=902ca282-d608-40fd-a710-6c11b49e872e"
                height="100"
                alt=""
              />
            </Link>

            {room.pay === true ? (
              <button
                style={styles.buttonActive}
                onClick={() => handleClick("payment")}
            //    to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Frealizar-checkout-white.png?alt=media&token=77296cd6-7a32-4b1f-88b7-b4b7de954d81"
                  height="100"
                  alt=""
                />
              </button>
            ) : (
              <button
                style={styles.button}
                onClick={() => handleClick("payment")}
             //   to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Frealizar-checkout-black.png?alt=media&token=24a9e6f4-50e3-45ad-9e30-405724d2776b"
                  height="100"
                  alt=""
                />
              </button>
            )}




                        
                        </div>


                        </>):(<>
        <div style={styles.buttons}>
                      


                          {room.noDisturb === true ? (
              <button
                style={styles.buttonActive}
                onClick={() => handleClick("doNotDisturb")}
            //    to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Fno-molestar-white-english.png?alt=media&token=7d09a517-04bf-41d7-9c7a-9af3a51211c1"
                  height="100"
                  alt=""
                />
              </button>
            ) : (
              <button
                style={styles.button}
                onClick={() => handleClick("doNotDisturb")}
            //    to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Fno-molestar-black-english.png?alt=media&token=ca6be4ac-6082-4f10-8c1b-72aa69b26fa1"
                  height="100"
                  alt=""
                />
              </button>
            )}


{room.clean === true ? (
              <button
                style={styles.buttonActive}
                onClick={() => handleClick("clean")}
            //    to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Flimpieza-habitacion-white-english.png?alt=media&token=02c381c7-5e8d-45be-857c-255452b7d4f1"
                  height="100"
                  alt=""
                />
              </button>
            ) : (
              <button
                style={styles.button}
                onClick={() => handleClick("clean")}
           //     to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Flimpieza-habitacion-black-english.png?alt=media&token=c6c7f69a-81f3-4485-afff-fee3d8433e7f"
                  height="100"
                  alt=""
                />
              </button>
            )}


<Link
              style={styles.button}
              to={`/${propsOfHotelId}/${language}/2812/menu`}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Fpedir-comida-black-english.png?alt=media&token=a04db38c-f164-40aa-8c18-9b7a144e2d3e"
                height="100"
                alt=""
              />
            </Link>

            {room.pay === true ? (
              <button
                style={styles.buttonActive}
                onClick={() => handleClick("payment")}
          //      to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Frealizar-checkout-white-english.png?alt=media&token=80925158-8963-4622-918a-dd4d9c08f414"
                  height="100"
                  alt=""
                />
              </button>
            ) : (
              <button
                style={styles.button}
                onClick={() => handleClick("payment")}
         //       to={`/${propsOfHotelId}/${language}/${propsOfRoomId}`}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbuttons%2Frealizar-checkout-black-english.png?alt=media&token=c930faa4-a665-4be3-8010-ce3fb624a2a6"
                  height="100"
                  alt=""
                />
              </button>
            )}




                        
                        </div>


                        </>)}










                </div>
             
          )}
        </div>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
