import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
//  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falmuerzo-cover.png?alt=media&token=720f0451-55d2-481b-a401-282fd2d34fcf";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  head: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "30px",
    backgroundColor: "white",
    borderBottom: "3px solid white",
    // margin: "30px",
  },
  nameOfMenu: {
    margin: 0,
    fontSize: 35,
    fontWeight: "bold",
    color: "#000",
    fontSize: 65,
    fontFamily: "SimpleJoys",
  },
  descriptionOfMenu: {
    margin: 0,
    marginRight: 20,
    marginTop: "5px",
    marginBottom: "10px",
    fontSize: 14,
    fontWeight: 400,
    color: "grey",
    //fontSize: 65,
    fontFamily: "LoewMedium",
  },
  menuContainer: {
    flex: 10,
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    overflow: "auto",
  },
  menu: {
    margin: 0,
    fontSize: 30,
    fontWeight: "bold",
    width: "100%",
    //  color: "#fff",
    //backgroundColor: "black",
    color: "#000",
    backgroundColor: "white",
    borderRadius: "25px",
    padding: "5px",
    //paddingLeft: "20px",
    //paddingRight: "20px",
  },
  menuName: {
    margin: 0,
    fontSize: 20,
    fontWeight: "bold",
    width: "100%",
    //  color: "#fff",
    //backgroundColor: "black",
    color: "#000",
    //backgroundColor: "blue",
    borderRadius: "25px",
    padding: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    // fontSize: 45,
    fontFamily: "LoewMedium",
  },
  menuHours: {
    margin: 0,
    fontSize: 12,
    //fontWeight: "bold",
    width: "100%",
    //  color: "#fff",
    color: "#000",
    //backgroundColor: "green",
    borderRadius: "25px",
    padding: "5px",
    // paddingLeft: "20px",
    // paddingRight: "20px",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "1",
  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  buttonFloatGoBack: {
    position: "absolute",
    top: 30,
    right: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //  borderRadius: "50px",
    // height: "50px",
    // width: "50px",
    backgroundColor: "transparent",
    color: "#fff",
    fontWeight: "bold",
    border: 'none',
    padding: 0,
    margin: 0
  },
  iconButtonGoBack: {
    height: "45px",
    width: "45px",
    color: "#000",
  },
};

export default ({
  hotel,
  whatsapp,
  menuObject,
  idHotel,
  idLanguage,
  loader,
  date,
  handleGoBack,
}) => {
  return (
    <>
      {loader == false ? (
        <div style={styles.container}>
          <div style={styles.head}>
            {idHotel === "TWdKMA7PTFfPPG7wdwwu" ? (
              <button style={styles.buttonFloatGoBack} onClick={() => handleGoBack()}>
                <svg xmlns="http://www.w3.org/2000/svg" style={styles.iconButtonGoBack} fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                </svg>
              </button>
            ) : (<></>)}
            {idLanguage === "spanish" ? (
              <>
                {idHotel === "UyXDBNtwRll1dKN6Dybz" ? (
                  <>
                    <h1 style={styles.nameOfMenu}>Nuestros servicios</h1>
                    <h6 style={styles.descriptionOfMenu}>
                      Elige el que prefieras.
                    </h6>
                  </>
                ) : (<> <h1 style={styles.nameOfMenu}>Menús</h1>
                  <h6 style={styles.descriptionOfMenu}>
                    Elige el lugar donde quieres comer.
                  </h6>
                </>)}
              </>
            ) : (
              <></>
            )}

            {idLanguage === "english" ? (
              <>
                {idHotel === "UyXDBNtwRll1dKN6Dybz" ? (
                  <>
                    <h1 style={styles.nameOfMenu}>Our services</h1>
                    <h6 style={styles.descriptionOfMenu}>
                      Choose the one you prefer.
                    </h6>
                  </>
                ) : (<>  <h1 style={styles.nameOfMenu}>Menus</h1>
                  <h6 style={styles.descriptionOfMenu}>
                    Choose the place where you want to eat.
                  </h6></>)}
              </>
            ) : (
              <></>
            )}

            {idLanguage === "portuguese" ? (
              <>
                <h1 style={styles.nameOfMenu}>Les menus</h1>
                <h6 style={styles.descriptionOfMenu}>
                  Escolha o lugar onde você quer comer.
                </h6>
              </>
            ) : (
              <></>
            )}

            {idLanguage === "french" ? (
              <>
                <h1 style={styles.nameOfMenu}>Menús</h1>
                <h6 style={styles.descriptionOfMenu}>
                  Choisissez l'endroit où vous voulez manger.
                </h6>
              </>
            ) : (
              <></>
            )}
          </div>
          {hotel.solutionWhatsapp === true ? (
            <Link
              style={styles.buttonFloat}
            //to={() => (window.location.href = "http://domain.com")}
            >
              <img
                onClick={() =>
                  // (window.location.href = `https://wa.me/%2B${whatsapp}`)
                  (window.location.href = `https://wa.me/%2B${whatsapp}`)
                }
                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                height="70"
                alt="whatsapp-icon"
              />
            </Link>
          ) : (
            <></>
          )}





          <div style={styles.menuContainer}>
            {menuObject
              ? menuObject.map((menu) => (
                // <div

                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     height: "160px",
                //     width: "90%",
                //     borderRadius: "25px",
                //     margin: "10px",
                //     marginTop: "10px",
                //     marginBottom: "10px",
                //   }}
                // >

                <>
                  {idHotel === "b01kkoD1WMfd4BQ3lvG" ? (
                    <>
                      <Link
                        key={menu.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",

                          width: "70%",
                          //      height: "400px",
                          maxHeight: "400px",
                          borderRadius: "30px",

                          margin: "10px",

                          backgroundColor: "white",

                          textDecoration: "none",
                          color: "inherit",
                        }}
                        to={`/${idHotel}/${idLanguage}/menu/${menu.id}/`}
                      //to={`/${idHotel}/menu/${menu.id}/${idRoom}`}
                      >
                        <img
                          style={{
                            width: "100%",
                            maxHeight: "400px",
                            borderRadius: "30px",
                          }}
                          src={menu.image}
                          alt="..."
                        />
                        {/* <div style={styles.menu}>
                          {idLanguage === "spanish" ? (
                            <>
                              {menu.nameOfMenuSpanish !== "" ? (
                                <>
                                  <h4 style={styles.menuName}>
                                    {menu.nameOfMenuSpanish}
                                  </h4>
                                </>
                              ) : (
                                <h4 style={styles.menuName}>{menu.name}</h4>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {idLanguage === "english" ? (
                            <>
                              {menu.nameOfMenuEnglish !== "" ? (
                                <>
                                  <h4 style={styles.menuName}>
                                    {menu.nameOfMenuEnglish}
                                  </h4>
                                </>
                              ) : (
                                <h4 style={styles.menuName}>{menu.name}</h4>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {idLanguage === "portuguese" ? (
                            <>
                              {menu.nameOfMenuPortuguese !== "" ? (
                                <>
                                  <h4 style={styles.menuName}>
                                    {menu.nameOfMenuPortuguese}
                                  </h4>
                                </>
                              ) : (
                                <h4 style={styles.menuName}>{menu.name}</h4>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {idLanguage === "french" ? (
                            <>
                              {menu.nameOfMenuFrench !== "" ? (
                                <>
                                  <h4 style={styles.menuName}>
                                    {menu.nameOfMenuFrench}
                                  </h4>
                                </>
                              ) : (
                                <h4 style={styles.menuName}>{menu.name}</h4>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          <h4 style={styles.menuHours}>{menu.hours}</h4>
                        </div> */}
                      </Link>
                    </>
                  ) : (
                    <Link
                      key={menu.id}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        height: "160px",
                        width: "90%",
                        padding: "50px",
                        borderRadius: "25px",
                        margin: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        // padding: "10px",
                        // paddingTop: "10px",
                        // marginBottom: "10px",
                        backgroundImage: `url(${menu.image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      to={`/${idHotel}/${idLanguage}/menu/${menu.id}/`}
                    //to={`/${idHotel}/menu/${menu.id}/${idRoom}`}
                    >
                      <div style={styles.menu}>
                        {idLanguage === "spanish" ? (
                          <>
                            {menu.nameOfMenuSpanish !== "" ? (
                              <>
                                <h4 style={styles.menuName}>
                                  {menu.nameOfMenuSpanish}
                                </h4>
                              </>
                            ) : (
                              <h4 style={styles.menuName}>{menu.name}</h4>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {idLanguage === "english" ? (
                          <>
                            {menu.nameOfMenuEnglish !== "" ? (
                              <>
                                <h4 style={styles.menuName}>
                                  {menu.nameOfMenuEnglish}
                                </h4>
                              </>
                            ) : (
                              <h4 style={styles.menuName}>{menu.name}</h4>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {idLanguage === "portuguese" ? (
                          <>
                            {menu.nameOfMenuPortuguese !== "" ? (
                              <>
                                <h4 style={styles.menuName}>
                                  {menu.nameOfMenuPortuguese}
                                </h4>
                              </>
                            ) : (
                              <h4 style={styles.menuName}>{menu.name}</h4>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {idLanguage === "french" ? (
                          <>
                            {menu.nameOfMenuFrench !== "" ? (
                              <>
                                <h4 style={styles.menuName}>
                                  {menu.nameOfMenuFrench}
                                </h4>
                              </>
                            ) : (
                              <h4 style={styles.menuName}>{menu.name}</h4>
                            )}
                          </>
                        ) : (
                          <></>
                        )}


                        {idHotel === "fNIFexhS3r0NsJl5qRhn" ? (
                          <>
                            {menu.id === "61tXaSGUVIoFFDlkHE7A" || menu.id === "YWjfap6Jtu1fAnMrv3fX" || menu.id === "M8QJe2VfoKw94bawEszL" || menu.id === "vgXuW8RmQNSCTHialGF5" ? (
                              <>
                                {menu.id === "61tXaSGUVIoFFDlkHE7A" ? (<>

                                  {idLanguage === "spanish" ? (<h4 style={styles.menuHours}>{menu.hours}</h4>) : (<h4 style={styles.menuHours}>Mon-Fri 11:00 am - 6:00 pm</h4>)}

                                </>) : (
                                  <>
                                    {menu.id === "YWjfap6Jtu1fAnMrv3fX" ? (<>

                                      {idLanguage === "spanish" ? (<h4 style={styles.menuHours}>{menu.hours}</h4>) : (<h4 style={styles.menuHours}>Mon-Fri 11:00 am - 6:00 pm</h4>)}

                                    </>) : (<>

                                      {menu.id === "M8QJe2VfoKw94bawEszL" ? (<>

                                        {idLanguage === "spanish" ? (<h4 style={styles.menuHours}>{menu.hours}</h4>) : (<h4 style={styles.menuHours}>Sat/Sun/Hol 12:30 pm - 3:00 pm</h4>)}

                                      </>) : (<>


                                        {idLanguage === "spanish" ? (<> <h4 style={styles.menuHours}>Lun-Vie 6:30 am - 10:30 am</h4><h4 style={styles.menuHours}>Sáb/Dom/Feriados 7 am - 11 am</h4></>) : (<><h4 style={styles.menuHours}>Mon-Fri 6:30 am - 10:30 am</h4><h4 style={styles.menuHours}>Sat/Sun/Hol 7:00 am - 11:00 am</h4></>)}

                                      </>)}



                                    </>)}


                                  </>

                                )}
                              </>
                            ) : (
                              <h4 style={styles.menuHours}>{menu.hours}</h4>
                            )}
                          </>
                        ) : (
                          <h4 style={styles.menuHours}>{menu.hours}</h4>
                        )}


                      </div>
                    </Link>
                  )}
                </>

                // </div>
              ))
              : null}
          </div>
        </div>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
