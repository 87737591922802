import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
  container: {},
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "white",
    borderBottom: "3px solid white",
    //margin: "30px",
  },

  nameOfProduct: {
    margin: 0,
    fontSize: 34,
    fontWeight: "bold",
    color: "#000",
    paddingTop: "15px",
    //fontSize: 55,
    fontFamily: "LoewBold",
  },
  descriptionOfProduct: {
    margin: 0,
    //marginTop: "5px",
    fontSize: 15,
    fontWeight: 400,
    color: "grey",
    padding: "15px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  noStock: {
    margin: 0,
    marginTop: "5px",
    fontSize: 14,
    fontWeight: 400,
    color: "white",
    padding: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "#ff2068",
    borderRadius: "20px",
  },
  price: {
    margin: 0,
    //marginTop: "15px",
    fontSize: 30,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },

  imageContainer: {
    //width: "60%",
    height: "100%",
    flex: 8,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-end",
    backgroundColor: "white",
    borderRadius: "0 0 0 70px",
    marginBotton: "200px",
  },
  imageContainerCenter: {
    //width: "60%",
    height: "100%",
    flex: 8,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "0 0 0 70px",
    marginBotton: "200px",
  },
  cart: {
    flex: 1,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px",
  },
  input: {
    flex: 7,
    display: "flex",
    borderRadius: "0px",
    borderColor: "#ffffff",
    color: "black",
    width: "90%",
    height: "50px",
    backgroundColor: "#white",
    borderRadius: "30px",
    marginRight: "10px",
  },

  quantityContainer: {
    flex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "30px",
    width: "140px",
    height: "50px",
  },
  quantity: {
    flex: "1",
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
    color: "black",
    padding: "10px",
  },
  button: {
    flex: "1",
    borderRadius: "30px",
    fontSize: "20px",
    color: "black",
    backgroundColor: "white",
    borderColor: "white",
  },

  buttonCart: {
    backgroundColor: "white",
    borderColor: "white",
    borderRadius: "0",
    width: "100%",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "black",
    borderRadius: "0 40px 0 0",
  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  buttonFloatGoBack: {
    position: "absolute",
    top: 30,
    right: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //  borderRadius: "50px",
    // height: "50px",
    // width: "50px",
    backgroundColor: "transparent",
    color: "#fff",
    fontWeight: "bold",
    border: 'none',
    padding: 0,
    margin: 0
  },
  iconButtonGoBack: {
    height: "45px",
    width: "45px",
    color: "#000",
  },
};

export default ({
  product,
  handleClick,
  handlerChange,
  addProd,
  value,
  lessProd,
  loader,
  hotel,
  whatsapp,
  language,
  idHotel,
  handleGoBack,
}) => {
  return (
    <>
      {loader == false ? (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            // backgroundColor: "#ff2068",
            backgroundColor: `${hotel.primaryColor}`,
          }}
        >
          {idHotel === "TWdKMA7PTFfPPG7wdwwu" ? (
            <button style={styles.buttonFloatGoBack} onClick={() => handleGoBack()}>
              <svg xmlns="http://www.w3.org/2000/svg" style={styles.iconButtonGoBack} fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
              </svg>
            </button>
          ) : (<></>)}
          <div style={styles.description}>
            {language === "spanish" ? (
              <>
                {product.nameSpanish !== "" ? (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.nameSpanish}</h1>
                  </>
                ) : (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.name}</h1>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {language === "english" ? (
              <>
                {product.nameEnglish !== "" ? (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.nameEnglish}</h1>
                  </>
                ) : (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.name}</h1>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {language === "portuguese" ? (
              <>
                {product.namePortuguese !== "" ? (
                  <>
                    <h1 style={styles.nameOfProduct}>
                      {product.namePortuguese}
                    </h1>
                  </>
                ) : (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.name}</h1>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {language === "french" ? (
              <>
                {product.nameFrench !== "" ? (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.nameFrench}</h1>
                  </>
                ) : (
                  <>
                    <h1 style={styles.nameOfProduct}>{product.name}</h1>
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            <>
              {language === "spanish" ? (
                <>
                  {product.descriptionSpanish !== "" ? (
                    <>
                      <h6 style={styles.descriptionOfProduct}>
                        {product.descriptionSpanish}
                      </h6>
                    </>
                  ) : (
                    <>
                      {product.description !== "" ? (
                        <h6 style={styles.descriptionOfProduct}>
                          {product.description}
                        </h6>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {language === "english" ? (
                <>
                  {product.descriptionEnglish !== "" ? (
                    <>
                      <h6 style={styles.descriptionOfProduct}>
                        {product.descriptionEnglish}
                      </h6>
                    </>
                  ) : (
                    <>
                      {product.description !== "" ? (
                        <h6 style={styles.descriptionOfProduct}>
                          {product.description}
                        </h6>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {language === "portuguese" ? (
                <>
                  {product.descriptionPortuguese !== "" ? (
                    <>
                      <h6 style={styles.descriptionOfProduct}>
                        {product.descriptionPortuguese}
                      </h6>
                    </>
                  ) : (
                    <>
                      {product.description !== "" ? (
                        <h6 style={styles.descriptionOfProduct}>
                          {product.description}
                        </h6>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {language === "french" ? (
                <>
                  {product.descriptionFrench !== "" ? (
                    <>
                      <h6 style={styles.descriptionOfProduct}>
                        {product.descriptionFrench}
                      </h6>
                    </>
                  ) : (
                    <>
                      {product.description !== "" ? (
                        <h6 style={styles.descriptionOfProduct}>
                          {product.description}
                        </h6>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>

            <>
              {product.stock === false ? (
                <h6 style={styles.noStock}>Sin stock</h6>
              ) : (
                <></>
              )}
            </>
          </div>

          {product.glutenFree || product.vegetarian || product.favourite ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 0.5,
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: "20px",
                backgroundColor: "white",
                borderColor: "white",
              }}
            >
              {product.glutenFree ? (
                <img
                  style={{
                    marginRight: "8px",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                  height="18"
                  alt="gluten-free-icon"
                />
              ) : (
                <></>
              )}

              {product.vegetarian ? (
                <img
                  style={{
                    marginRight: "8px",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                  height="18"
                  alt="gluten-free-icon"
                />
              ) : (
                <></>
              )}

              {product.favourite ? (
                <img
                  style={{
                    marginRight: "8px",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                  height="18"
                  alt="gluten-free-icon"
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {hotel.solutionWhatsapp === true ? (
            <Link
              style={styles.buttonFloat}
            //to={() => (window.location.href = "http://domain.com")}
            >
              <img
                onClick={() =>
                  // (window.location.href = `https://wa.me/%2B${whatsapp}`)
                  (window.location.href = ` https://wa.me/5491136926957?text=Hola!%20Estoy%20en%20la%20habitaci%C3%B3n%20N%C3%BAmero%20(completar%20con%20el%20n%C3%BAmero).%20Quisiera%20pedir%20los%20siguientes%20alimentos%3A%20(completar)`)
                }
                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                height="75"
                alt="whatsapp-icon"
              />
            </Link>
          ) : (
            <></>
          )}




          <div style={idHotel === "fNIFexhS3r0NsJl5qRhn" ? (styles.imageContainerCenter) : (styles.imageContainer)}>
            <img
              style={idHotel === "fNIFexhS3r0NsJl5qRhn" ? ({
                width: "70%",
                maxHeight: "95%",
                borderRadius: "40px",
              }) : ({
                width: "70%",
                maxHeight: "95%",
                borderRadius: "40px 0 0 40px",
                paddingRight: "0px",
              })}
              src={product.imageProduct}
              alt="..."
            />
          </div>
          <div style={styles.cart}>
            {idHotel === "TWdKMA7PTFfPPG7wdwwu" || idHotel === "JLIP6024rbF3vHnaE85K" ? (
              <h6 style={styles.price}>S/{product.price}</h6>
            ) : (
              <h6 style={styles.price}>${product.price}</h6>
            )}
          </div>
        </div>

      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
