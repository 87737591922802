import {
  SET_HOTEL_ID,
  SET_HOTEL_INFO,
  SET_ROOMS_INFO,
  SET_LANGUAGE_INFO,
  ADD_MENU_QUANTITY,
  DOWN_MENU_QUANTITY,
  ADD_CATEGORY_QUANTITY,
  DOWN_CATEGORY_QUANTITY,
  ADD_PRODUCT_QUANTITY,
  DOWN_PRODUCT_QUANTITY,
  ADD_DISABLE_QUANTITY,
  DOWN_DISABLE_QUANTITY,
} from "../reducers/constant";

const addHotelId = (hoteldata) => ({
  type: SET_HOTEL_ID,
  hoteldata,
});

const addHotelInfo = (hoteldata) => ({
  type: SET_HOTEL_INFO,
  hoteldata,
});

const addRoomsInfo = (hoteldata) => ({
  type: SET_ROOMS_INFO,
  hoteldata,
});

const addLanguageInfo = (hoteldata) => ({
  type: SET_LANGUAGE_INFO,
  hoteldata,
});

const addMenuQuantity = (hoteldata) => ({
  type: ADD_MENU_QUANTITY,
  hoteldata,
});

const downMenuQuantity = (hoteldata) => ({
  type: DOWN_MENU_QUANTITY,
  hoteldata,
});

const addCategoryQuantity = (hoteldata) => ({
  type: ADD_CATEGORY_QUANTITY,
  hoteldata,
});

const downCategoryQuantity = (hoteldata) => ({
  type: DOWN_CATEGORY_QUANTITY,
  hoteldata,
});

const addProductQuantity = (hoteldata) => ({
  type: ADD_PRODUCT_QUANTITY,
  hoteldata,
});

const downProductQuantity = (hoteldata) => ({
  type: DOWN_PRODUCT_QUANTITY,
  hoteldata,
});

const addDisableQuantity = (hoteldata) => ({
  type: ADD_DISABLE_QUANTITY,
  hoteldata,
});

const downDisableQuantity = (hoteldata) => ({
  type: DOWN_DISABLE_QUANTITY,
  hoteldata,
});

// const addIdTable = table => ({
//   type: SET_ID_TABLE,
//   table
// });

// const addIdRestaurant = restaurant => ({
//   type: SET_ID_RESTAURANT,
//   restaurant
// });

export const saveHotelId = (hotelId) => (dispatch) =>
  dispatch(addHotelId(hotelId));

export const saveHotel = (hotelInfo) => (dispatch) =>
  dispatch(addHotelInfo(hotelInfo));

export const saveLanguage = (hotelInfo) => (dispatch) =>
  dispatch(addLanguageInfo(hotelInfo));

export const saveRooms = (hotelInfo) => (dispatch) =>
  dispatch(addRoomsInfo(hotelInfo));

export const saveAddMenuQuantity = (hotelInfo) => (dispatch) =>
  dispatch(addMenuQuantity(hotelInfo));

export const saveDownMenuQuantity = (hotelInfo) => (dispatch) =>
  dispatch(downMenuQuantity(hotelInfo));

export const saveAddCategoryQuantity = (hotelInfo) => (dispatch) =>
  dispatch(addCategoryQuantity(hotelInfo));

export const saveDownCategoryQuantity = (hotelInfo) => (dispatch) =>
  dispatch(downCategoryQuantity(hotelInfo));

export const saveAddProductQuantity = (hotelInfo) => (dispatch) =>
  dispatch(addProductQuantity(hotelInfo));

export const saveDownProductQuantity = (hotelInfo) => (dispatch) =>
  dispatch(downProductQuantity(hotelInfo));

export const saveAddDisableQuantity = (hotelInfo) => (dispatch) =>
  dispatch(addDisableQuantity(hotelInfo));

export const saveDownDisableQuantity = (hotelInfo) => (dispatch) =>
  dispatch(downDisableQuantity(hotelInfo));

// export const saveTable = table => dispatch => dispatch(addIdTable(table));

// export const saveRestaurant = restaurant => dispatch =>
//   dispatch(addIdRestaurant(restaurant));
