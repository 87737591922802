import {
  SET_HOTEL_ID,
  SET_HOTEL_INFO,
  SET_LANGUAGE_INFO,
  SET_ROOMS_INFO,
  ADD_MENU_QUANTITY,
  DOWN_MENU_QUANTITY,
  ADD_CATEGORY_QUANTITY,
  DOWN_CATEGORY_QUANTITY,
  ADD_PRODUCT_QUANTITY,
  DOWN_PRODUCT_QUANTITY,
  ADD_DISABLE_QUANTITY,
  DOWN_DISABLE_QUANTITY,
} from "../reducers/constant";

const initialState = {
  hotelId: "",
  hotelInfo: {},
  language: "",
  rooms: [],
  totalMenues: 0,
  totalCategories: 0,
  totalProducts: 0,
  totalDisableProducts: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOTEL_ID:
      return { ...state, hotelId: action.hoteldata };
    case SET_HOTEL_INFO:
      return {
        ...state,
        hotelInfo: action.hoteldata,
        totalMenues: action.hoteldata.totalMenues,
        totalCategories: action.hoteldata.totalCategories,
        totalProducts: action.hoteldata.totalProducts,
        totalDisableProducts: action.hoteldata.productsWithoutStock,
      };
    case SET_LANGUAGE_INFO:
      return { ...state, language: action.hoteldata };

    case SET_ROOMS_INFO:
      return { ...state, rooms: action.hoteldata };

    case ADD_MENU_QUANTITY:
      return { ...state, totalMenues: action.hoteldata };
    case DOWN_MENU_QUANTITY:
      return { ...state, totalMenues: action.hoteldata };

    case ADD_CATEGORY_QUANTITY:
      return { ...state, totalCategories: action.hoteldata };
    case DOWN_CATEGORY_QUANTITY:
      return { ...state, totalCategories: action.hoteldata };

    case ADD_PRODUCT_QUANTITY:
      return { ...state, totalProducts: action.hoteldata };
    case DOWN_PRODUCT_QUANTITY:
      return { ...state, totalProducts: action.hoteldata };

    case ADD_DISABLE_QUANTITY:
      return { ...state, totalDisableProducts: action.hoteldata };
    case DOWN_DISABLE_QUANTITY:
      return { ...state, totalDisableProducts: action.hoteldata };

    default:
      return state;
  }
};
