import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//const Background =
//  "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falmuerzo-cover.png?alt=media&token=720f0451-55d2-481b-a401-282fd2d34fcf";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  head: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "30px",
    backgroundColor: "white",
    borderBottom: "3px solid white",
    // margin: "30px",
  },
  nameOfMenu: {
    margin: 0,
    fontSize: 35,
    fontWeight: "bold",
    color: "#000",
    fontSize: 65,
    fontFamily: "SimpleJoys",
  },
  descriptionOfMenu: {
    margin: 0,
    marginRight: 20,
    marginTop: "8px",
    fontSize: 14,
    fontWeight: 400,
    color: "grey",

    //fontSize: 65,
    fontFamily: "LoewMedium",
  },
  menuContainer: {
    flex: 10,
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    overflow: "auto",
  },
  menu2: {
    margin: 0,
    fontSize: 30,
    fontWeight: "bold",
    width: "100%",
    //  color: "#fff",
    //backgroundColor: "black",
    color: "#fff",
    // backgroundColor: "white",
    borderRadius: "25px",
    padding: "5px",
    //paddingLeft: "20px",
    //paddingRight: "20px",
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    fontSize: 25,
    fontWeight: "bold",
    fontFamily: "LoewMedium",
    backgroundColor: "black",
    color: "#000",
    borderRadius: "25px",
    padding: "5px",
    height: "100%",
    width: "100%",
    position: "relative",
    background: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
  },
  menuName: {
    margin: 0,
    fontSize: 25,
    fontWeight: "bold",
    width: "100%",
    //  color: "#fff",
    //backgroundColor: "black",
    color: "#fff",
    backgroundColor: "blue",
    //backgroundColor: "blue",
    borderRadius: "25px",
    padding: "5px",
    //paddingLeft: "180px",
    //paddingRight: "180px",
    // fontSize: 45,
    fontFamily: "LoewMedium",
  },
  menuHours: {
    margin: 0,
    fontSize: 12,
    //fontWeight: "bold",
    width: "100%",
    //  color: "#fff",
    color: "#000",
    //backgroundColor: "green",
    borderRadius: "25px",
    padding: "5px",
    // paddingLeft: "20px",
    // paddingRight: "20px",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "1",
  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  survey: {
    flex: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#339AE7",
    padding: "15px",
  },
  surveyText: {
    margin: 0,
    fontSize: 16,
    // fontWeight: "400",
    textAlign: "center",
    color: "#fff",
    fontFamily: "LoewMedium",
  },
};

export default ({
  menuObject,
  hotel,
  whatsapp,
  outlets,
  idHotel,
  idLanguage,
  loader,
}) => {
  return (
    <>
      {loader == false ? (
        <div style={styles.container}>
          <div style={styles.head}>
            {idLanguage === "spanish" ? (
              <>
                <h1 style={styles.nameOfMenu}>Lugares!</h1>
                <h6 style={styles.descriptionOfMenu}>
                  Elige el lugar donde quieres comer.
                </h6>
              </>
            ) : (
              <></>
            )}

            {idLanguage === "english" ? (
              <>
                <h1 style={styles.nameOfMenu}>Places!</h1>
                <h6 style={styles.descriptionOfMenu}>
                  Choose the place where you want to eat.
                </h6>
              </>
            ) : (
              <></>
            )}

            {idLanguage === "portuguese" ? (
              <>
                <h1 style={styles.nameOfMenu}>Lugares!</h1>
                <h6 style={styles.descriptionOfMenu}>
                  Escolha o lugar onde você quer comer.
                </h6>
              </>
            ) : (
              <></>
            )}

            {idLanguage === "french" ? (
              <>
                <h1 style={styles.nameOfMenu}>Places!</h1>
                <h6 style={styles.descriptionOfMenu}>
                  Choisissez l'endroit où vous voulez manger.
                </h6>
              </>
            ) : (
              <></>
            )}
          </div>

          {hotel.solutionWhatsapp === true ? (
            <Link
              style={styles.buttonFloat}
            //to={() => (window.location.href = "http://domain.com")}
            >
              <img
                onClick={() =>
                  // (window.location.href = `https://wa.me/%2B${whatsapp}`)
                  (window.location.href = ` https://wa.me/5491136926957?text=Hola!%20Estoy%20en%20la%20habitaci%C3%B3n%20N%C3%BAmero%20(completar%20con%20el%20n%C3%BAmero).%20Quisiera%20pedir%20los%20siguientes%20alimentos%3A%20(completar)`)
                }
                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                height="75"
                alt="whatsapp-icon"
              />
            </Link>
          ) : (
            <></>
          )}

          <div style={styles.menuContainer}>
            {outlets
              ? outlets.map((outlet) => (
                <>
                  {outlet.active === true ? (
                    <Link
                      key={outlet.id}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        height: "160px",
                        width: "90%",
                        // padding: "50px",
                        borderRadius: "25px",
                        margin: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        // padding: "10px",
                        // paddingTop: "10px",
                        // marginBottom: "10px",
                        backgroundImage: `url(${outlet.imageOutlet})`,
                        backgroundRepeat: "no-repeat",
                        //backgroundColor: "#fff",
                        backgroundSize: "cover",
                        textDecoration: "none",
                        color: "inherit",
                        // opacity: "0.5",
                      }}
                      to={`/${idHotel}/${idLanguage}/${outlet.id}/menu`}
                    //to={`/${idHotel}/menu/${menu.id}/${idRoom}`}
                    >
                      <div style={styles.menu}>
                        {/* <h4 style={styles.menuName}> */}
                        {outlet.name}
                        {/* </h4> */}
                      </div>
                    </Link>
                  ) : (<></>)}
                </>
              ))
              : null}
          </div>
          {hotel.solutionSurvey === true ? (
            <Link
              style={styles.survey}
              onClick={() =>
                (window.location.href = `https://lovable.typeform.com/to/Y9pVsV`)
              }
            >
              <h6 style={styles.surveyText}>Realizar Encuesta</h6>
            </Link>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
