import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
//import Loader from "react-loader-spinner";
import firebase from "./services/firebase";
import Login from "./restaurant/containers/views/Login";
//import Rooms from "./restaurant/containers/views/Rooms";
import Configurations from "./restaurant/containers/views/Configurations";
import Home from "./client/containers/views/Home";
import HomeHotelFull from "./client/containers/views/HomeHotelsFull";
//nimport HomeInmobiliarias from "./client/containers/views/HomeInmobiliarias";
//import HomeHilton from "./client/containers/views/HomeHilton";
import LoginClient from "./client/containers/views/Login";
import LoginNoUser from "./client/containers/views/LoginNoUser";
import Orders from "./restaurant/containers/views/Orders";
import RecoverPassword from "./restaurant/components/views/RecoverPassword";
import Menues from "./restaurant/containers/views/Menues";
import Outlets from "./restaurant/containers/views/Outlets";
import Dashboard from "./restaurant/containers/views/Dashboard";
import Categories from "./restaurant/containers/views/Categories";
import Products from "./restaurant/containers/views/Products";
import SingleRoom from "./restaurant/containers/views/SingleRoom";
import CreateHotel from "./restaurant/containers/create/Hotel";
import CreateOutlet from "./restaurant/containers/create/Outlet";
import CreateMenu from "./restaurant/containers/create/Menu";
import CreateCategory from "./restaurant/containers/create/Category";
import CreateProduct from "./restaurant/containers/create/Product";
import EditProduct from "./restaurant/containers/edit/Product";
import EditCategory from "./restaurant/containers/edit/Category";
import EditMenu from "./restaurant/containers/edit/Menu";
import EditOutlet from "./restaurant/containers/edit/Outlet";
import EditConfigurations from "./restaurant/containers/edit/Configurations";
import MenuesClient from "./client/containers/views/Menues";
import OutletsClient from "./client/containers/views/Outlets";
import CategoriesClient from "./client/containers/views/Categories";
import ProductsClient from "./client/containers/views/Products";
import Cart from "./client/containers/views/Cart";
import Information from "./client/components/views/Information";
import ChatDashboard from "./restaurant/containers/views/Chat";
import Chat from "./client/components/views/Chat";
import Password from "./restaurant/components/edit/Password";
import Mail from "./client/components/views/Mail";
import Navbar from "./restaurant/containers/general/Navbar";
import OrdersHistory from "./restaurant/containers/views/OrdersHistory";

const mapStateToProps = (state) => {
  return {
    userLogin: state.user.loginUser,
    isAuth: state.user.isAuth,
  };
};

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      firebaseInitialized: false,
    };
  }

  componentDidMount() {
    firebase.isInitialized().then((val) => {
      this.setState({
        firebaseInitialized: val,
      });
    });
  }
  // componentDidUpdate(prevState) {
  //   prevState.isAuth !== this.props.isAuth &&
  //     this.props.userLogin.name &&
  //     this.props.history.push("/dashboard");
  // }

  render() {
    return this.props.userLogin.name ? (
      <div>
        <Navbar />
        <Switch>
          {/* Views */}
          <Route path="/chat" component={ChatDashboard} />
          <Route path="/orders/history" component={OrdersHistory} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/configuration/edit" component={EditConfigurations} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/configurations" component={Configurations} />
          <Route path="/configurations/resetpassword" component={Password} />
          <Route path="/recover" component={RecoverPassword} />
          {/* Create */}
          <Route path="/create/hotel" component={CreateHotel} />
          <Route path="/menu/createMenu" component={CreateMenu} />
          <Route path="/outlet/createOutlet" component={CreateOutlet} />
          <Route path="/menu/:id/createCategory" component={CreateCategory} />
          <Route
            path="/menu/:id/:categoryId/createProduct"
            component={CreateProduct}
          />
          {/* Edit */}
          <Route
            path="/menu/:id/:categoryId/editProduct/:productId"
            component={EditProduct}
          />
          <Route
            path="/menu/:id/:categoryId/editCategory"
            component={EditCategory}
          />
          <Route path="/outlet/:id/editOutlet" component={EditOutlet} />
          <Route path="/menu/:id/editMenu" component={EditMenu} />
          {/* Rutas a Menu/Categorias/Productos */}
          <Route exact path="/menu" component={Menues} />
          <Route exact path="/outlets" component={Outlets} />
          <Route exact path="/menu/:id/:categoryId" component={Products} />
          <Route path="/menu/:id" component={Categories} />
          <Route path="/configurations" component={Configurations} />
          <Route
            path="/forms"
            component={() => {
              window.location.href = "https://lovable.typeform.com/to/Y9pVsV";
              return null;
            }}
          />
          <Route
            path="/whatsapp"
            component={() => {
              window.location.href =
                "https://api.whatsapp.com/send?phone=+5491136926953&text=Hola%21%20Me%20gustar%C3%ADa%20conocer%20más%20acerca%20de%20...&source=&data=&app_absent=";
              return null;
            }}
          />
          <Route
            exact
            path="/tables/:idTable"
            component={withRouter(SingleRoom)}
          />
          <Route exact path="/login" component={Login}></Route>

          {/* Cliente */}
          {/* <Route path="/pruebahome" component={HomeInmobiliarias} /> */}
          <Route exact path="/" component={LoginNoUser}></Route>

          <Route path="/:idHotel/language" component={Home} />

          <Route
            path="/:idHotel/:idLanguage/:idMenu/:idCategoria/:idProduct/client"
            component={ProductsClient}
          />
          {/* <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/:idRoom/client"
            component={ProductsClient}
          /> */}
          <Route
            path="/:idHotel/:idLanguage/menu/:idMenu/"
            component={CategoriesClient}
          />
          {/* <Route
            path="/:idHotel/menu/:idMenu/:idRoom"
            component={CategoriesClient}
          /> */}

          <Route
            path="/:idHotel/:idLanguage/outlets"
            component={OutletsClient}
          />
          <Route
            path="/:idHotel/:idLanguage/:idOutlet/menu"
            component={MenuesClient}
          />
          {/* <Route path="/:idHotel/:idRoom/menu" component={MenuesClient} /> */}

          {/* <Route path="/:idHotel/language" component={HomeHilton} /> */}
          <Route path="/:idHotel/cart/:idRoom" component={Cart} />
          <Route path="/:idHotel/rooms" component={LoginClient} />
          {/* <Route path="/:idHotel/login" component={LoginNoUser} /> */}
          {/* <Route
            path="/:idHotel/menu/:idMenu/:idRoom"
            component={CategoriesClient}
          /> */}
          <Route path="/:idHotel/menu/:idMenu/" component={CategoriesClient} />

          {/* <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/:idRoom/client"
            component={ProductsClient}
          /> */}
          <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/client"
            component={ProductsClient}
          />
          <Route path="/:idRestaurant/:idTable/mail" component={Mail} />
          <Route path="/:idHotel/:idRoom/information" component={Information} />
          <Route path="/:idHotel/:idRoom/chat" component={Chat} />
          <Route path="/:idHotel/:idLanguage/login" component={LoginClient} />
          <Route path="/:idHotel/:idLanguage/:idRoom" component={HomeHotelFull} />
        </Switch>
      </div>
    ) : (
      <div>
        <Switch>
          {/* Views */}
          <Route path="/chat" component={ChatDashboard} />
          <Route path="/orders/history" component={OrdersHistory} />

          <Route path="/dashboard" component={Dashboard} />
          <Route path="/configuration/edit" component={EditConfigurations} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/configurations" component={Configurations} />
          <Route path="/configurations/resetpassword" component={Password} />
          <Route path="/recover" component={RecoverPassword} />
          {/* Create */}
          <Route path="/create/hotel" component={CreateHotel} />
          <Route path="/menu/createMenu" component={CreateMenu} />
          <Route path="/outlet/createOutlet" component={CreateOutlet} />
          <Route path="/menu/:id/createCategory" component={CreateCategory} />
          <Route
            path="/menu/:id/:categoryId/createProduct"
            component={CreateProduct}
          />
          {/* Edit */}
          <Route
            path="/menu/:id/:categoryId/editProduct/:productId"
            component={EditProduct}
          />
          <Route
            path="/menu/:id/:categoryId/editCategory"
            component={EditCategory}
          />
          <Route path="/menu/:id/editMenu" component={EditMenu} />
          <Route path="/outlet/:id/editOutlet" component={EditOutlet} />
          {/* Rutas a Menu/Categorias/Productos */}
          <Route exact path="/menu" component={Menues} />
          <Route exact path="/outlets" component={Outlets} />
          <Route exact path="/menu/:id/:categoryId" component={Products} />
          <Route path="/menu/:id" component={Categories} />

          <Route path="/configurations" component={Configurations} />
          <Route
            path="/forms"
            component={() => {
              window.location.href = "https://lovable.typeform.com/to/Y9pVsV";
              return null;
            }}
          />
          <Route
            path="/whatsapp"
            component={() => {
              window.location.href = "https://wa.me/%2B5491136926953";
              return null;
            }}
          />

          <Route
            exact
            path="/tables/:idTable"
            component={withRouter(SingleRoom)}
          />

          <Route exact path="/login" component={Login}></Route>
          {/* Cliente */}
          {/* <Route path="/pruebahome" component={HomeInmobiliarias} /> */}
          <Route exact path="/" component={LoginNoUser}></Route>
          <Route path="/:idHotel/language" component={Home} />
          <Route path="/:idHotel/cart/:idRoom" component={Cart} />
          <Route path="/:idHotel/tables" component={LoginClient} />
          <Route path="/:idHotel/login" component={LoginNoUser} />
          <Route
            path="/:idHotel/:idLanguage/:idMenu/:idCategoria/:idProduct/client"
            component={ProductsClient}
          />
          {/* <Route
            path="/:idHotel/:idMenu/:idCategoria/:idProduct/:idRoom/client"
            component={ProductsClient}
          /> */}
          <Route
            path="/:idHotel/:idLanguage/menu/:idMenu/"
            component={CategoriesClient}
          />
          {/* <Route
            path="/:idHotel/menu/:idMenu/:idRoom"
            component={CategoriesClient}
          /> */}

          <Route
            path="/:idHotel/:idLanguage/outlets"
            component={OutletsClient}
          />
          <Route
            path="/:idHotel/:idLanguage/:idOutlet/menu"
            component={MenuesClient}
          />
          {/* <Route path="/:idHotel/:idRoom/menu" component={MenuesClient} /> */}

          <Route path="/:idRestaurant/:idTable/mail" component={Mail} />
          <Route path="/:idHotel/:idRoom/information" component={Information} />
          <Route path="/:idHotel/:idRoom/chat" component={Chat} />
          <Route path="/:idHotel/:idLanguage/login" component={LoginClient} />
          <Route path="/:idHotel/:idLanguage/:idRoom" component={HomeHotelFull} />
        </Switch>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Main);
