import React from "react";
import Menues from "../../../client/components/views/Menues";
import firebase from "../../../services/firebase";
import { connect } from "react-redux";
import { saveMenues } from "../../../store/actions/menuClientActions";
const DB = firebase.db;

const mapStateToProps = (state, ownprops) => {
  return {
    hotelId: state.hotel.hotelId,
    menuesHotel: state.menu.menues,
    outlets: state.menu.outlets,
    hotelInfoRedux: state.hotel.hotelInfo,
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    saveMenues: (menues) => dispatch(saveMenues(menues)),
  };
};

class MenuesContainerCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuesAndNames: [],
      loader: true,
      hotel: {},
    };
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount() {
    //Pregunta primero si tiene o no activado el outlets.

    if (this.props.match.params.idOutlet !== "2812") {
      //REVISAR si siempre ir a buscar todos los menues

      //siempre guardar menues en redux.

      let outletObj = {};
      let arrayMenues = [];
      let outletsDoc = DB.collection("hoteles")
        .doc(`${this.props.match.params.idHotel}`)
        .collection("outlets")
        .doc(`${this.props.match.params.idOutlet}`);
      outletsDoc.get().then((outlet) => {
        outletObj = {
          name: outlet.data().name,
          arrayMenues: outlet.data().arrayMenues,
        };

        outletObj.arrayMenues
          ? outletObj.arrayMenues.map((outlet) => {
            let menuDoc = DB.collection("hoteles")
              .doc(`${this.props.match.params.idHotel}`)
              .collection("menues")
              .doc(`${outlet}`);

            menuDoc
              .get()
              .then((menu) => {
                if (menu.data()) {
                  if (menu.data().active !== false) {
                    arrayMenues.push({
                      name: menu.data().nameOfMenu,

                      nameOfMenuSpanish: menu.data().nameOfMenuSpanish,
                      nameOfMenuEnglish: menu.data().nameOfMenuEnglish,
                      nameOfMenuPortuguese: menu.data().nameOfMenuPortuguese,
                      nameOfMenuFrench: menu.data().nameOfMenuFrench,

                      aclarationSpanish: menu.data().aclarationSpanish,
                      aclarationEnglish: menu.data().aclarationEnglish,
                      aclarationPortuguese: menu.data().aclarationPortuguese,
                      aclarationFrench: menu.data().aclarationFrench,

                      image: menu.data().imageMenu,
                      hours: menu.data().hours,
                      id: menu.id,
                    });
                  }
                }
              })
              .then(() => {
                // this.props.saveMenues(arrayMenues);
                this.setState({
                  menuesAndNames: arrayMenues,
                  loader: false,
                  hotel: this.props.hotelInfoRedux,
                });
              });
          })
          : console.log("otro");
      });
    } else {
      if (this.props.menuesHotel.length) {
        this.setState({
          menuesAndNames: this.props.menuesHotel,
          loader: false,
        });
      } else {
        let arrayMenues = [];
        let menuesDoc = DB.collection("hoteles")
          .doc(this.props.match.params.idHotel)
          .collection("menues")
          .orderBy("orderMenu", "asc");
        menuesDoc
          .get()
          .then((menues) => {
            menues.forEach((menuesFB) => {
              if (menuesFB.data().active !== false) {
                arrayMenues.push({
                  name: menuesFB.data().nameOfMenu,

                  nameOfMenuSpanish: menuesFB.data().nameOfMenuSpanish,
                  nameOfMenuEnglish: menuesFB.data().nameOfMenuEnglish,
                  nameOfMenuPortuguese: menuesFB.data().nameOfMenuPortuguese,
                  nameOfMenuFrench: menuesFB.data().nameOfMenuFrench,

                  aclarationSpanish: menuesFB.data().aclarationSpanish,
                  aclarationEnglish: menuesFB.data().aclarationEnglish,
                  aclarationPortuguese: menuesFB.data().aclarationPortuguese,
                  aclarationFrench: menuesFB.data().aclarationFrench,

                  image: menuesFB.data().imageMenu,
                  hours: menuesFB.data().hours,
                  id: menuesFB.id,
                });
              }
            });
          })
          .then(() => {
            this.props.saveMenues(arrayMenues);
            this.setState({
              menuesAndNames: arrayMenues,
              loader: false,
              hotel: this.props.hotelInfoRedux,
            });
          });
      }
    }
  }

  handleGoBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <Menues
          idLanguage={this.props.match.params.idLanguage}
          idHotel={this.props.match.params.idHotel}
          menuObject={this.state.menuesAndNames}
          loader={this.state.loader}
          hotel={this.props.hotelInfoRedux}
          whatsapp={this.props.hotelInfoRedux.whatsappNumber}
          handleGoBack={this.handleGoBack}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuesContainerCliente);
