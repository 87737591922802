import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    backgroundColor: "white",
    marginLeft: "250px",
    paddingLeft: "35px",
    paddingRight: "35px",
    paddingTop: "20px",
  },
  createMenu: {
    textDecoration: "none",
    color: "#ffffff",
    backgroundColor: "#ff2068",
    borderColor: "#ff2068",
    marginTop: "20px",
    marginBottom: "10px",
  },
};

export default ({ menuObject, deleteFunc, changeActive, rol }) => {
  return (
    <div style={styles.container}>
      <h1 className="font-weight-bold">Current Menues</h1>

      {rol === "admin" ? (
        <Link
          style={styles.createMenu}
          className="btn btn-primary"
          to="/menu/createMenu"
        >
          Create Menu
        </Link>
      ) : (
        <></>
      )}

      <hr />

      <ul
        className="list-group"
        style={{
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        {menuObject ? (
          menuObject.map((menu) => {
            return (
              <li
                key={menu.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <Link
                  style={{
                    display: "flex",
                    flex: 2,
                  }}
                  to={`/menu/${menu.id}`}
                >
                  <img
                    style={{
                      maxWidth: "100px",
                      marginRight: "20px",
                      display: "flex",
                      flex: 12,
                    }}
                    src={menu.imageMenu}
                    alt="category img"
                  />
                </Link>
                <Link
                  style={{
                    display: "flex",
                    flex: 2,
                    //fontWeight: "bold",
                  }}
                  to={`/menu/${menu.id}`}
                >
                  {menu.name}
                </Link>
                <Link
                  style={{
                    display: "flex",
                    flex: 8,
                    //fontWeight: "bold",
                  }}
                  to={`/menu/${menu.id}`}
                >
                  {menu.hours}
                </Link>

                {rol === "admin" ? (
                  <>
                    {menu.active !== false ? (
                      <div>
                        <Link to={`/menu/${menu.id}/editMenu`}>
                          <button
                            style={{
                              backgroundColor: "#339AE7",
                              borderColor: "#339AE7",
                              marginRight: "20px",
                            }}
                            className="btn btn-primary btn-lg"
                          >
                            Edit
                          </button>
                        </Link>
                        <button
                          onClick={(e) => changeActive(e, menu.id, menu.active)}
                          style={{
                            backgroundColor: "#ff2068",
                            borderColor: "#ff2068",
                            marginRight: "20px",
                          }}
                          className="btn btn-primary btn-lg"
                        >
                          Disable
                        </button>
                        <button
                          onClick={(e) => deleteFunc(e, menu.id)}
                          style={{
                            backgroundColor: "#ff2068",
                            borderColor: "#ff2068",
                          }}
                          className="btn btn-primary btn-lg"
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <div>
                        <Link to={`/menu/${menu.id}/editMenu`}>
                          <button
                            style={{
                              backgroundColor: "#339AE7",
                              borderColor: "#339AE7",
                              marginRight: "20px",
                            }}
                            className="btn btn-primary btn-lg"
                          >
                            Edit
                          </button>
                        </Link>
                        <button
                          onClick={(e) => changeActive(e, menu.id, menu.active)}
                          style={{
                            backgroundColor: "#2EC4B6",
                            borderColor: "#2EC4B6",
                            marginRight: "20px",
                          }}
                          className="btn btn-primary btn-lg"
                        >
                          Enable
                        </button>
                        <button
                          onClick={(e) => deleteFunc(e, menu.id)}
                          style={{
                            backgroundColor: "#ff2068",
                            borderColor: "#ff2068",
                          }}
                          className="btn btn-primary btn-lg"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </li>
            );
          })
        ) : (
          <li>No Menues Found</li>
        )}
      </ul>
    </div>
  );
};
