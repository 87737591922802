import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const firebaseConfig = {
  apiKey: "AIzaSyDIPo4-pU3DjBXS9HIzXePOB23IGgeausM",
  authDomain: "hotels-prod-a5b59.firebaseapp.com",
  databaseURL: "https://hotels-prod-a5b59.firebaseio.com",
  projectId: "hotels-prod-a5b59",
  storageBucket: "hotels-prod-a5b59.appspot.com",
  messagingSenderId: "876261457966",
  appId: "1:876261457966:web:c538c1632c3752855d9fbf",
  measurementId: "G-SKPJ8VHWRB",
};

//Utility class
class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    //reference to auth api
    this.auth = app.auth();
    //access to cloud firestore
    this.db = app.firestore();
  }

  login(email, password) {
    //Nos retorna una promesa, lo manejamos mas adelante
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }
  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser.displayName;
  }

  succesfullMsg(param) {
    MySwal.fire({
      position: "center",
      icon: "success",
      title: param,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}

export default new Firebase();
