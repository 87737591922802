import React from "react";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  containerFlex: {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },

  head: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingLeft: "30px",
    //  backgroundColor: "white",
    // borderBottom: "3px solid white",
    margin: 0,
  },
  nameOfMenu: {
    margin: 0,
    fontSize: 35,
    fontWeight: "bold",
    color: "#000",
    fontSize: 55,
    fontFamily: "SimpleJoys",
  },
  descriptionOfMenu: {
    margin: 0,
    marginTop: "15px",
    fontSize: 13,
    fontWeight: 400,
    color: "white",
    backgroundColor: "rgb(180,180,180)",
    padding: "5px",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "20px",
  },
  categoriesContainer: {
    flex: 10,
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    // flexWrap: "wrap",
    //  backgroundColor: "white",
    overflow: "auto",
  },
  categoryName: {
    margin: 0,
    fontSize: 26,
    fontWeight: "bold",
    color: "#000",
    padding: "20px",
    paddingTop: "30px",
    // fontSize: 45,
    fontFamily: "LoewMedium",
  },

  categoryNameCenter: {
    //
    textAlign: "center",
    //
    margin: 0,
    fontSize: 26,
    fontWeight: "bold",
    color: "#000",
    padding: "20px",
    paddingTop: "30px",
    // fontSize: 45,
    fontFamily: "LoewMedium",
  },

  productsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    //  backgroundColor: "white",
  },
  productsContainerListImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "white",
    flexDirection: "column",
    width: "100vw",
  },
  productListImage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row-reverse",
    height: "100px",
    width: "90vw",
    borderRadius: "10px",
    margin: "10px",
    backgroundColor: "white",
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  imageContainerList: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    height: `100%`,
    width: `100%`,
  },
  imageList: {
    //flex: 12,
    display: "flex",
    width: "80%",
    borderRadius: "20px",
    maxHeight: "150px",
    maxWidth: "160px",
    marginTop: "5px",
  },

  indicationsListImage: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "green",
    flexDirection: "column",
    width: "100vw",
  },
  indicationsTextListImage: {
    padding: "20px",
    margin: 0,
    fontSize: 12,
    color: "#000",
    // padding: 100,
    textAlign: "center",
    lineHeight: "1.5",
  },
  indicatorListImage: {
    margin: "0px 4px"
  },
  productDescriptionListImage: {
    flex: 6,
    display: "flex",
    height: '100%',
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 15
  },
  productNameListImage: {
    margin: "0px 0px 5px",
    fontSize: 16,
    fontWeight: "bold",
    color: "#000",
    textAlign: "left",
    fontFamily: "LoewBold",
  },
  productPriceListImage: {
    margin: "5px 0px 0px",
    fontSize: 12,
    color: "#000",
  },
  menuContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  product: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
    height: "250px",
    width: "43.5vw",
    borderRadius: "20px",
    margin: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "white",
    boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  imageContainer: {
    flex: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    // justifyContent: "flex-end",
    // alignItems: "flex-end",
    height: `100%`,
    width: `100%`,
  },
  image: {
    //flex: 12,
    display: "flex",
    width: "80%",
    borderRadius: "20px",
    maxHeight: "150px",
    maxWidth: "160px",
    marginTop: "5px",
  },

  indications: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "green",
    flexDirection: "column",
    width: "100vw",
  },
  indicationsText: {
    padding: "20px",

    margin: 0,
    fontSize: 12,
    color: "#000",
    // padding: 100,
    textAlign: "center",
    lineHeight: "1.5",
  },

  productsWithoutImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "white",
    flexDirection: "column",
    width: "100vw",
  },
  productWithoutImageGeneral: {
    display: "flex",
    flexDirection: 'column'
    // justifyContent: "space-between",
    // alignItems: "center",
  },
  productWithoutImage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "90vw",
    margin: "15px 10px 0px"
    // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
  },
  productNameAndDescriptionWithoutImage: {
    display: "flex",
    flex: 9,
    flexDirection: "column",
    margin: '10px 5px 0px 0px'
  },

  productNameWithoutImage: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#000",
    margin: 0,
    // fontSize: 45,
    fontFamily: "LoewBold",
  },

  productDescriptionWithoutImage: {
    fontSize: 12,
    fontWeight: "400",
    color: "grey",
    marginTop: 4,
    marginBottom: 4,
  },

  productPriceWithoutImage: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 3,
    margin: 0,
    marginLeft: 10,
    fontSize: 12,
    color: "#000",
  },


  indicators: {
    //  flex: 2,
    display: "flex",
    flexDirection: "row",
    marginBottom: "0px",
    justifyContent: "center",
    alignItems: "center",
    padding: 8
  },
  indicatorsNoImage: {
    display: "flex",
    flexDirection: "row",
    padding: "4px 0px",
    margin: '0px 10px 0px'
  },
  indicator: {
    margin: "0px 4px"
  },
  indicatorNoImage: {
    margin: "0px 8px 0px 0px",
    height: "14px"
  },
  productDescription: {
    flex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  productName: {
    margin: 0,
    marginLeft: 10,
    marginRight: 10,
    fontSize: 14,
    fontWeight: "bold",
    color: "#000",
    textAlign: "center",
    // fontSize: 45,
    fontFamily: "LoewBold",
  },
  productPrice: {
    margin: 3,
    marginBottom: 12,
    fontSize: 12,
    color: "#000",
  },
  cartContainer: {
    flex: 1.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonCart: {
    height: "100%",
    width: "100%",
    fontSize: "20px",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#339AE7",
    backgroundColor: "#339AE7",
    borderRadius: "40px 40px 0 0",
  },
  buttonFloat: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: "50px",
    //backgroundColor: "green",
  },
  buttonFloatGoBack: {
    position: "absolute",
    top: 30,
    right: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //  borderRadius: "50px",
    // height: "50px",
    // width: "50px",
    backgroundColor: "transparent",
    color: "#fff",
    fontWeight: "bold",
    border: 'none',
    padding: 0,
    margin: 0
  },
  iconButtonGoBack: {
    height: "45px",
    width: "45px",
    color: "#000",
  },
  buttonFloatBack: {
    position: "absolute",
    top: 10,
    left: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    height: "50px",
    width: "50px",
    backgroundColor: "rgba(90,90,90,0.2)",
    fontWeight: "bold",
  },
  imagePDF: {
    width: "100%",
  },
  imagesMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100vw',
    margin: '30px 0px 10px'
  },
  imagesMenu1: {
    height: '150px'
  },
  imagesMenu2: {
    margin: '40px 0px 0px',
    height: '250px'
  },
  imagesMenu3: {
    height: '250px'
  },
  imagesMenuTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100vw',
    margin: '30px 0px',
    padding: '0px 10vw'
  },
  imagesMenuText: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '1.5'
  },
  imagesMenuCategoryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100vw',
    margin: '10px 0px',
    padding: '0px 10vw'
  },
  imagesMenuCategoryText: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '1.5'
  },
};

export default ({
  productos,
  categoria,
  idRoom,
  idHotel,
  idMenu,
  numOrder,
  prod,
  loader,
  hotel,
  whatsapp,
  menuSelectedName,
  menuSelectedNameSpanish,
  menuSelectedNameEnglish,
  menuSelectedNamePortuguese,
  menuSelectedNameFrench,
  menuSelectedHours,
  menuSelectedId,
  aclarationSpanish,
  aclarationEnglish,
  aclarationPortuguese,
  aclarationFrench,
  language,
  handleEdit,
  handleClick,
  handleGoBack,
  state,
}) => {
  const productosOrdenados = productos.sort(function (a, b) {
    return b.numberOfBuys - a.numberOfBuys;
  });
  const tresProductosMasVendidos = [];
  if (productosOrdenados.length) {
    for (var i = 0; i < 3; i++) {
      tresProductosMasVendidos.push(productosOrdenados[i]);
    }
  }

  let colorBackground = {
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  };


  if (idHotel === "fNIFexhS3r0NsJl5qRhn") {
    if (
      idMenu === "QIaYJjCmLZHzci1MXRk5" ||
      idMenu === "vgXuW8RmQNSCTHialGF5"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-desayuno.png?alt=media&token=25546664-656d-4051-a9c4-e20d72231162"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (idMenu === "M8QJe2VfoKw94bawEszL") {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-roof-almuerzo.png?alt=media&token=f07012af-6707-4c98-b0f9-9fab59e0773f"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (idMenu === "Yf7Tj3kWTbejsW0CiWgJ") {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-croutons-promociones.png?alt=media&token=c81c2a58-d871-4ea6-811d-846eb3fd36cc"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (idMenu === "hF76RTOJBRj2ntfN0kfF") {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-croutons-desayuno.png?alt=media&token=219275ab-22be-4694-a759-4daf7fe261cf"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (idMenu === "o1d7FH4hO8u9AMYHBwWJ") {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-croutons-almuerzo.png?alt=media&token=979eb580-95b8-4870-9dbb-5f523e26bc6e"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "slybV9lIbsUnkIas0yTB" ||
      idMenu === "RjETlAspbkU69fFcMYUN" ||
      idMenu === "j9VmoCWVnnAfYQP3m2Vy"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-almuerzo.png?alt=media&token=b573b54f-5159-463c-94b8-f4b82c48ad02"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "M5C00iFOjWnfEw25CsfE" ||
      idMenu === "kGeZ7HmojqkrdansaBKs"
      //  || idMenu === "QgD9d2I6LxeGrfVDhWnW"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-cena.png?alt=media&token=a07c905d-b7b4-4a6d-a3d2-8118143cc2c2"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "djjualGfJag1pvz9EDNt" ||
      idMenu === "lvHGLlMU1VFRajFud7uS" ||
      idMenu === "bVOjFEFqMtAZE1yXsiTI" ||
      idMenu === "vBM3RJ6NNYHpZYtMggWU" ||
      idMenu === "HzBZcJVszdMF4Aufs33g"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-bebidas.png?alt=media&token=4253adab-0c0c-48b0-97f5-55ae0f72a601"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "VBxy4ZznXBqYNtA7502P" ||
      idMenu === "dm95ug2UMHjPxkJS0BgY" ||
      idMenu === "KEArJJZ7jKcKgssqiev7" ||
      idMenu === "NPTC2cBcTXabfZnxxM2v"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-cervezas.png?alt=media&token=c0509672-3783-4172-b5be-d3361a4e0b6c"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "nvwDHMi4oJQegFvv1GL1" ||
      idMenu === "asWZCNA2jMHN3eZncMTX" ||
      idMenu === "GSxPUcRlXSyzco1qsj8i" ||
      idMenu === "MxIjhjR0f8kSEOQhIG2V"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-vinos.png?alt=media&token=e7056fb1-7242-4353-927a-4e7cab48d6da"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "hb6OGmyFI1f72QJcseoA" ||
      idMenu === "NGyeJXqpUEiVmnEHMDVQ" ||
      idMenu === "aat1MiEKFYjwr1Ri8pwb"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Hilton-BuenosAires%2Fbackground-hilton-buenosaires-destilados.png?alt=media&token=37584d10-0068-4b0b-92e8-314277a8a069"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "tClh8OJn5IaeKk86KiUj"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://i.postimg.cc/bNYC1184/background-hilton-buenosaires-minibar.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "r5QNahkMmmNGaozemkaF"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://i.postimg.cc/132vxT6Z/background-hilton-buenosaires-promociones.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "yMDW8ue8rrkfYiusHamQ"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://i.postimg.cc/xdmt9RyN/background-hilton-buenosaires-snack.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "ow0JiWtvkiCVlACcQciY" || idMenu === "dK3oOIhNvws4Yd7oCigX"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://i.postimg.cc/j2cMGS68/background-hilton-buenosaires-kids.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "MtLRYjCckND9yWuRRmt0"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://i.postimg.cc/Jnb65qsC/background-hilton-buenosaires-cocktails.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }

    if (
      idMenu === "U81CS0RWunzRaNSH5FO2"
    ) {
      colorBackground = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${"https://i.postimg.cc/C1YHSVx9/background-hilton-buenosaires-cafeteria.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    }


  }
  // if (idHotel !== "fNIFexhS3r0NsJl5qRhn") {
  //   colorBackground = {
  //     position: "fixed",
  //     bottom: 0,
  //     top: 0,
  //     left: 0,
  //     right: 0,
  //     display: "flex",
  //     flexDirection: "column",
  //     backgroundColor: "#fff",
  //   };
  // }

  return (
    <>
      {loader === false ? (
        <>
          {state.popUp == true ? (
            <>
              <img style={styles.imagePDF} src={`${state.selectedPDF}`} />

              <div
                style={styles.buttonFloatBack}
                onClick={(e) => handleClick(e, "close")}
              // src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
              // height="25"
              >
                X
              </div>
            </>
          ) : (
            //  <div style={styles.container}>
            <div style={colorBackground}>
              {idHotel === "TWdKMA7PTFfPPG7wdwwu" ? (
                <button style={styles.buttonFloatGoBack} onClick={() => handleGoBack()}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={styles.iconButtonGoBack} fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                  </svg>
                </button>
              ) : (<></>)}
              <div style={styles.head}>
                {language === "spanish" ? (
                  <>
                    {menuSelectedNameSpanish !== "" ? (
                      <h1 style={styles.nameOfMenu}>
                        {menuSelectedNameSpanish}
                      </h1>
                    ) : (
                      <h1 style={styles.nameOfMenu}>{menuSelectedName}</h1>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {language === "english" ? (
                  <>
                    {menuSelectedNameEnglish !== "" ? (
                      <h1 style={styles.nameOfMenu}>
                        {menuSelectedNameEnglish}
                      </h1>
                    ) : (
                      <h1 style={styles.nameOfMenu}>{menuSelectedName}</h1>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {language === "portuguese" ? (
                  <>
                    {menuSelectedNamePortuguese !== "" ? (
                      <h1 style={styles.nameOfMenu}>
                        {menuSelectedNamePortuguese}
                      </h1>
                    ) : (
                      <h1 style={styles.nameOfMenu}>{menuSelectedName}</h1>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {language === "french" ? (
                  <>
                    {menuSelectedNameFrench !== "" ? (
                      <h1 style={styles.nameOfMenu}>
                        {menuSelectedNameFrench}
                      </h1>
                    ) : (
                      <h1 style={styles.nameOfMenu}>{menuSelectedName}</h1>
                    )}
                  </>
                ) : (
                  <></>
                )}


                {idHotel === "fNIFexhS3r0NsJl5qRhn" ? (
                  <>
                    {menuSelectedId === "61tXaSGUVIoFFDlkHE7A" || menuSelectedId === "YWjfap6Jtu1fAnMrv3fX" || menuSelectedId === "M8QJe2VfoKw94bawEszL" || menuSelectedId === "vgXuW8RmQNSCTHialGF5" || menuSelectedId === "QgD9d2I6LxeGrfVDhWnW" ? (



                      <>
                        {menuSelectedId === "QgD9d2I6LxeGrfVDhWnW" ? (<>

                          <h6
                            style={{
                              margin: 0,
                              // marginTop: "15px",
                              fontSize: 13,
                              fontWeight: 400,
                              color: "white",
                              //backgroundColor: "rgb(180,180,180)",
                              backgroundColor: `#000`,
                              padding: "5px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              borderRadius: "20px",
                            }}
                          >
                            EL FARO RESTAURANT
                          </h6>


                        </>) : (
                          <>
                            {menuSelectedId === "61tXaSGUVIoFFDlkHE7A" ? (<>
                              {language === "spanish" ? (<h6
                                style={{
                                  margin: 0,
                                  // marginTop: "15px",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "white",
                                  //backgroundColor: "rgb(180,180,180)",
                                  backgroundColor: `${hotel.primaryColor}`,
                                  padding: "5px",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                  borderRadius: "20px",
                                }}
                              >
                                {menuSelectedHours}
                              </h6>) : (<h6
                                style={{
                                  margin: 0,
                                  // marginTop: "15px",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "white",
                                  //backgroundColor: "rgb(180,180,180)",
                                  backgroundColor: `${hotel.primaryColor}`,
                                  padding: "5px",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                  borderRadius: "20px",
                                }}
                              >
                                Mon-Fri 11:00 am - 6:00 pm
                              </h6>
                              )}
                            </>) : (
                              <>
                                {menuSelectedId === "YWjfap6Jtu1fAnMrv3fX" ? (<>
                                  {language === "spanish" ? (<h6
                                    style={{
                                      margin: 0,
                                      // marginTop: "15px",
                                      fontSize: 13,
                                      fontWeight: 400,
                                      color: "white",
                                      //backgroundColor: "rgb(180,180,180)",
                                      backgroundColor: `${hotel.primaryColor}`,
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      paddingRight: "15px",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {menuSelectedHours}
                                  </h6>) : (<h6
                                    style={{
                                      margin: 0,
                                      // marginTop: "15px",
                                      fontSize: 13,
                                      fontWeight: 400,
                                      color: "white",
                                      //backgroundColor: "rgb(180,180,180)",
                                      backgroundColor: `${hotel.primaryColor}`,
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      paddingRight: "15px",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    Mon-Fri 11:00 am - 6:00 pm
                                  </h6>
                                  )}
                                </>) : (<>
                                  {menuSelectedId === "M8QJe2VfoKw94bawEszL" ? (<>
                                    {language === "spanish" ? (<h6
                                      style={{
                                        margin: 0,
                                        // marginTop: "15px",
                                        fontSize: 13,
                                        fontWeight: 400,
                                        color: "white",
                                        //backgroundColor: "rgb(180,180,180)",
                                        backgroundColor: `${hotel.primaryColor}`,
                                        padding: "5px",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                        borderRadius: "20px",
                                      }}
                                    >
                                      {menuSelectedHours}
                                    </h6>) : (<h6
                                      style={{
                                        margin: 0,
                                        // marginTop: "15px",
                                        fontSize: 13,
                                        fontWeight: 400,
                                        color: "white",
                                        //backgroundColor: "rgb(180,180,180)",
                                        backgroundColor: `${hotel.primaryColor}`,
                                        padding: "5px",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                        borderRadius: "20px",
                                      }}
                                    >
                                      Sat/Sun/Hol 12:30 pm - 3:00 pm
                                    </h6>
                                    )}
                                  </>) : (<>
                                    {language === "spanish" ? (<> <h6
                                      style={{
                                        margin: 0,
                                        // marginTop: "15px",
                                        fontSize: 13,
                                        fontWeight: 400,
                                        color: "white",
                                        //backgroundColor: "rgb(180,180,180)",
                                        backgroundColor: `${hotel.primaryColor}`,
                                        padding: "5px",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                        borderRadius: "20px",
                                      }}
                                    >
                                      Lun-Vie 6:30 am - 10:30 am
                                    </h6>
                                      <h6
                                        style={{
                                          margin: 0,
                                          // marginTop: "15px",
                                          fontSize: 13,
                                          fontWeight: 400,
                                          color: "white",
                                          //backgroundColor: "rgb(180,180,180)",
                                          backgroundColor: `${hotel.primaryColor}`,
                                          padding: "5px",
                                          paddingLeft: "15px",
                                          paddingRight: "15px",
                                          borderRadius: "20px",
                                        }}
                                      >
                                        Sáb/Dom/Feriados 7 am - 11 am
                                      </h6>
                                    </>) : (
                                      <><h6
                                        style={{
                                          margin: 0,
                                          // marginTop: "15px",
                                          fontSize: 13,
                                          fontWeight: 400,
                                          color: "white",
                                          //backgroundColor: "rgb(180,180,180)",
                                          backgroundColor: `${hotel.primaryColor}`,
                                          padding: "5px",
                                          paddingLeft: "15px",
                                          paddingRight: "15px",
                                          borderRadius: "20px",
                                        }}
                                      >
                                        Mon-Fri 6:30 am - 10:30 am
                                      </h6>
                                        <h6
                                          style={{
                                            margin: 0,
                                            // marginTop: "15px",
                                            fontSize: 13,
                                            fontWeight: 400,
                                            color: "white",
                                            //backgroundColor: "rgb(180,180,180)",
                                            backgroundColor: `${hotel.primaryColor}`,
                                            padding: "5px",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                            borderRadius: "20px",
                                          }}
                                        >
                                          Sat/Sun/Hol 7:00 am - 11:00 am
                                        </h6>
                                      </>
                                    )}
                                  </>)}
                                </>)}
                              </>
                            )}
                          </>





                        )}
                      </>





                    ) : (
                      <h6
                        style={{
                          margin: 0,
                          // marginTop: "15px",
                          fontSize: 13,
                          fontWeight: 400,
                          color: "white",
                          //backgroundColor: "rgb(180,180,180)",
                          backgroundColor: `${hotel.primaryColor}`,
                          padding: "5px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          borderRadius: "20px",
                        }}
                      >
                        {menuSelectedHours}
                      </h6>
                    )}
                  </>
                ) : (
                  <h6
                    style={{
                      margin: 0,
                      // marginTop: "15px",
                      fontSize: 13,
                      fontWeight: 400,
                      color: "white",
                      //backgroundColor: "rgb(180,180,180)",
                      backgroundColor: `${hotel.primaryColor}`,
                      padding: "5px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      borderRadius: "20px",
                    }}
                  >
                    {menuSelectedHours}
                  </h6>
                )}






              </div>
              {hotel.solutionWhatsapp === true ? (
                <Link
                  style={styles.buttonFloat}
                //to={() => (window.location.href = "http://domain.com")}
                >
                  <img
                    onClick={() =>
                      // (window.location.href = `https://wa.me/%2B${whatsapp}`)
                      (window.location.href = `https://wa.me/%2B${whatsapp}`)
                    }
                    src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fwhatsapp-logo.png?alt=media&token=577f9926-5c47-42ba-a0c5-af980d340f01"
                    height="70"
                    alt="whatsapp-icon"
                  />
                </Link>
              ) : (
                <></>
              )}

              <div style={styles.categoriesContainer}>

                {categoria ? (
                  categoria.map((categ) => {
                    return (
                      <>
                        <div>
                          {hotel.positionTitleCategory === "center" ? (
                            <>
                              {language === "spanish" ? (
                                <>
                                  {categ.nameCategoriaSpanish !== "" ? (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoriaSpanish}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {language === "english" ? (
                                <>
                                  {categ.nameCategoriaEnglish !== "" ? (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoriaEnglish}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {language === "portuguese" ? (
                                <>
                                  {categ.nameCategoriaPortuguese !== "" ? (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoriaPortuguese}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {language === "french" ? (
                                <>
                                  {categ.nameCategoriaFrench !== "" ? (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoriaFrench}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryNameCenter}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {language === "spanish" ? (
                                <>
                                  {categ.nameCategoriaSpanish !== "" ? (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoriaSpanish}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {language === "english" ? (
                                <>
                                  {categ.nameCategoriaEnglish !== "" ? (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoriaEnglish}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {language === "portuguese" ? (
                                <>
                                  {categ.nameCategoriaPortuguese !== "" ? (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoriaPortuguese}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              {language === "french" ? (
                                <>
                                  {categ.nameCategoriaFrench !== "" ? (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoriaFrench}
                                    </h3>
                                  ) : (
                                    <h3 style={styles.categoryName}>
                                      {categ.nameCategoria}
                                    </h3>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}


                          {categ.id == "LmMOKf9tHFSrisSB6Woo" ? (
                            <div style={styles.imagesMenuCategoryContainer}>
                              <h2 style={styles.imagesMenuCategoryText}>
                                Productos artesanales hechos por nuestro equipo de cocina, madurados en su tiempo para alcanzar los sabores que nos distinguen
                              </h2>
                            </div>
                          ) : (<></>)}


                          <>
                            {idHotel === "b01kBkD1WMfd4BQ3lvG" ? (
                              <Link
                                style={styles.menuContainer}
                                onClick={(e) =>
                                  handleClick(e, categ.imageCategory)
                                }
                              >
                                <img
                                  style={{
                                    width: "90%",
                                    maxHeight: "400px",
                                    borderRadius: "30px",
                                  }}
                                  src={categ.imageCategory}
                                  alt="..."
                                />
                              </Link>
                            ) : (
                              <>
                                {categ.withImages ? (
                                  <>
                                    {idHotel === "TWdKMA7PTFfPPG7wdwwu" ? (
                                      //CON IMAGENES PERO FORMATO LISTA PARA HILTON LIMA MIRAFLORES
                                      <>
                                        <div style={styles.productsContainerListImage}>
                                          {categ.products ? (
                                            categ.products.map((product, index) => {
                                              if (product.stock === true) {
                                                return (
                                                  <Link
                                                    onClick={(e) => handleEdit(e, index, categ.id, product.name, product.price)}
                                                    style={{ textDecoration: "none", color: "inherit", }}
                                                  >
                                                    <div style={styles.productListImage}>
                                                      {product.imageProduct !== "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdefault.png?alt=media&token=4f76adfe-5a06-4f94-a587-3553682ca2b3" ? (
                                                        <div style={styles.imageContainerList}>
                                                          <div style={{
                                                            display: "flex",
                                                            backgroundImage: `url(${product.imageProduct})`,
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center",
                                                            margin: 0,
                                                            padding: 0,
                                                            // height: `80%`,
                                                            // width: `80%`,
                                                            height: `90%`,
                                                            width: `90%`,
                                                            borderRadius: "10px",
                                                          }} />
                                                        </div>
                                                      ) : (<></>)}
                                                      <div style={styles.productDescriptionListImage}>
                                                        {language === "spanish" ? (
                                                          <>
                                                            {product.nameSpanish !== "" ? (
                                                              <h6 style={styles.productNameListImage}>{product.nameSpanish}</h6>
                                                            ) : (
                                                              <h6 style={styles.productNameListImage}>{product.name}</h6>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}

                                                        {language === "english" ? (
                                                          <>
                                                            {product.nameEnglish !== "" ? (
                                                              <h6 style={styles.productNameListImage}>{product.nameEnglish}</h6>
                                                            ) : (
                                                              <h6 style={styles.productNameListImage}>{product.name}</h6>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {language === "portuguese" ? (
                                                          <>
                                                            {product.namePortuguese !== "" ? (
                                                              <h6 style={styles.productNameListImage}>{product.namePortuguese}</h6>
                                                            ) : (
                                                              <h6 style={styles.productNameListImage}>{product.name}</h6>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}

                                                        {language === "french" ? (
                                                          <>
                                                            {product.nameFrench !== "" ? (
                                                              <h6 style={styles.productNameListImage}>{product.nameFrench}</h6>
                                                            ) : (
                                                              <h6 style={styles.productNameListImage}>{product.name}</h6>
                                                            )}
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {product.glutenFree || product.vegetarian || product.favourite ? (
                                                          <div style={styles.indicationsListImage}>
                                                            {product.glutenFree ? (
                                                              <img
                                                                style={styles.indicatorListImage}
                                                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                                                                height="18"
                                                                alt="gluten-free-icon"
                                                              />
                                                            ) : (<></>)}
                                                            {product.vegetarian ? (
                                                              <img
                                                                style={styles.indicatorListImage}
                                                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                                                                height="18"
                                                                alt="vegetarian-icon"
                                                              />
                                                            ) : (<></>)}
                                                            {product.favourite ? (
                                                              <img
                                                                style={styles.indicatorListImage}
                                                                src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                                                                height="18"
                                                                alt="favourite-icon"
                                                              />
                                                            ) : (<></>)}
                                                          </div>
                                                        ) : (<></>)}
                                                        {idHotel ===
                                                          "TWdKMA7PTFfPPG7wdwwu" ? (
                                                          <h6 style={styles.productPriceListImage}>S/{product.price}</h6>
                                                        ) : (
                                                          <h6 style={styles.productPriceListImage}>$ {product.price}</h6>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </Link>
                                                );
                                              }
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>) : (
                                      <div style={styles.productsContainer}>
                                        {categ.products ? (
                                          categ.products.map((product, index) => {
                                            if (product.stock === true) {
                                              return (
                                                <Link
                                                  onClick={(e) => handleEdit(e, index, categ.id, product.name, product.price)}
                                                  style={{ textDecoration: "none", color: "inherit", }}
                                                >
                                                  <div style={styles.product}>
                                                    <div style={styles.imageContainer} >
                                                      <div style={{
                                                        display: "flex",
                                                        backgroundImage: `url(${product.imageProduct})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "cover",
                                                        backgroundPosition: "center",
                                                        margin: 0,
                                                        padding: 0,
                                                        height: `80%`,
                                                        width: `80%`,
                                                        borderRadius: "20px",
                                                      }} />
                                                      {product.glutenFree || product.vegetarian || product.favourite ? (
                                                        <div style={styles.indicators}>
                                                          {product.glutenFree ? (
                                                            <img
                                                              style={styles.indicator}
                                                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                                                              height="18"
                                                              alt="gluten-free-icon"
                                                            />
                                                          ) : (<></>)}
                                                          {product.vegetarian ? (
                                                            <img
                                                              style={styles.indicator}
                                                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                                                              height="18"
                                                              alt="vegetarian-icon"
                                                            />
                                                          ) : (<></>)}
                                                          {product.favourite ? (
                                                            <img
                                                              style={styles.indicator}
                                                              src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                                                              height="18"
                                                              alt="favourite-icon"
                                                            />
                                                          ) : (<></>)}
                                                        </div>
                                                      ) : (<></>)}
                                                    </div>
                                                    <div style={styles.productDescription}>
                                                      {language === "spanish" ? (
                                                        <>
                                                          {product.nameSpanish !== "" ? (
                                                            <h6 style={styles.productName}>{product.nameSpanish}</h6>
                                                          ) : (
                                                            <h6 style={styles.productName}>{product.name}</h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}

                                                      {language === "english" ? (
                                                        <>
                                                          {product.nameEnglish !== "" ? (
                                                            <h6 style={styles.productName}>{product.nameEnglish}</h6>
                                                          ) : (
                                                            <h6 style={styles.productName}>{product.name}</h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {language === "portuguese" ? (
                                                        <>
                                                          {product.namePortuguese !== "" ? (
                                                            <h6 style={styles.productName}>{product.namePortuguese}</h6>
                                                          ) : (
                                                            <h6 style={styles.productName}>{product.name}</h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}

                                                      {language === "french" ? (
                                                        <>
                                                          {product.nameFrench !== "" ? (
                                                            <h6 style={styles.productName}>{product.nameFrench}</h6>
                                                          ) : (
                                                            <h6 style={styles.productName}>{product.name}</h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {idHotel ===
                                                        "TWdKMA7PTFfPPG7wdwwu" ? (
                                                        <h6 style={styles.productPrice}>S/{product.price}</h6>
                                                      ) : (
                                                        <h6 style={styles.productPrice}>$ {product.price}</h6>
                                                      )}
                                                    </div>
                                                  </div>
                                                </Link>
                                              );
                                            }
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    style={styles.productsWithoutImageContainer}
                                  >
                                    {categ.products ? (
                                      categ.products.map((product, index) => {
                                        if (product.stock === true) {
                                          return (
                                            <div style={styles.productWithoutImageGeneral}>
                                              <div
                                                style={styles.productWithoutImage}
                                              >
                                                <div
                                                  style={
                                                    styles.productNameAndDescriptionWithoutImage
                                                  }
                                                >
                                                  {language === "spanish" ? (
                                                    <>
                                                      {product.nameSpanish !==
                                                        "" ? (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.nameSpanish}
                                                        </h6>
                                                      ) : (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.name}
                                                        </h6>
                                                      )}

                                                      {product.description !==
                                                        "" ? (
                                                        <>
                                                          {product.descriptionSpanish !==
                                                            "" ? (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {
                                                                product.descriptionSpanish
                                                              }
                                                            </h6>
                                                          ) : (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {product.description}
                                                            </h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {language === "english" ? (
                                                    <>
                                                      {product.nameEnglish !==
                                                        "" ? (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.nameEnglish}
                                                        </h6>
                                                      ) : (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.name}
                                                        </h6>
                                                      )}

                                                      {product.description !==
                                                        "" ? (
                                                        <>
                                                          {product.descriptionEnglish !==
                                                            "" ? (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {
                                                                product.descriptionEnglish
                                                              }
                                                            </h6>
                                                          ) : (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {product.description}
                                                            </h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {language === "portuguese" ? (
                                                    <>
                                                      {product.namePortuguese !==
                                                        "" ? (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.namePortuguese}
                                                        </h6>
                                                      ) : (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.name}
                                                        </h6>
                                                      )}

                                                      {product.description !==
                                                        "" ? (
                                                        <>
                                                          {product.descriptionPortuguese !==
                                                            "" ? (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {
                                                                product.descriptionPortuguese
                                                              }
                                                            </h6>
                                                          ) : (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {product.description}
                                                            </h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {language === "french" ? (
                                                    <>
                                                      {product.nameFrench !== "" ? (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.nameFrench}
                                                        </h6>
                                                      ) : (
                                                        <h6
                                                          style={
                                                            styles.productNameWithoutImage
                                                          }
                                                        >
                                                          {product.name}
                                                        </h6>
                                                      )}

                                                      {product.description !==
                                                        "" ? (
                                                        <>
                                                          {product.descriptionFrench !==
                                                            "" ? (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {
                                                                product.descriptionFrench
                                                              }
                                                            </h6>
                                                          ) : (
                                                            <h6
                                                              style={
                                                                styles.productDescriptionWithoutImage
                                                              }
                                                            >
                                                              {product.description}
                                                            </h6>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {/* {product.glutenFree ||
                                                product.vegetarian ||
                                                product.favourite ? (
                                                <div style={styles.indicatorsNoImage}>
                                                  {product.glutenFree ? (
                                                    <img
                                                      style={styles.indicatorNoImage}
                                                      src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                                                      alt="gluten-free-icon"
                                                    />
                                                  ) : (<></>)}
                                                  {product.vegetarian ? (
                                                    <img
                                                      style={styles.indicatorNoImage}
                                                      src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                                                      alt="vegetarian-icon"
                                                    />
                                                  ) : (<></>)}
                                                  {product.favourite ? (
                                                    <img
                                                      style={styles.indicatorNoImage}
                                                      src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                                                      alt="favourite-icon"
                                                    />
                                                  ) : (<></>)}
                                                </div>
                                              ) : (
                                                <></>
                                              )} */}
                                                </div>

                                                {product.price !== "" ? (
                                                  <>
                                                    {idHotel === "TWdKMA7PTFfPPG7wdwwu" || idHotel === "JLIP6024rbF3vHnaE85K" ? (
                                                      <h6
                                                        style={
                                                          styles.productPriceWithoutImage
                                                        }
                                                      >
                                                        S/{product.price}
                                                      </h6>
                                                    ) : (
                                                      <h6
                                                        style={
                                                          styles.productPriceWithoutImage
                                                        }
                                                      >
                                                        $ {product.price}
                                                      </h6>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                              {product.glutenFree ||
                                                product.vegetarian ||
                                                product.favourite ? (
                                                <div style={styles.indicatorsNoImage}>
                                                  {product.glutenFree ? (
                                                    <img
                                                      style={styles.indicatorNoImage}
                                                      src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fgluten-free.png?alt=media&token=d4bf7bcc-5ea9-46ae-bf13-3c346fc62a2e"
                                                      alt="gluten-free-icon"
                                                    />
                                                  ) : (<></>)}
                                                  {product.vegetarian ? (
                                                    <img
                                                      style={styles.indicatorNoImage}
                                                      src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fvegan.png?alt=media&token=9ec97273-fe14-4cc8-8048-9e9c5c1a4c12"
                                                      alt="vegetarian-icon"
                                                    />
                                                  ) : (<></>)}
                                                  {product.favourite ? (
                                                    <img
                                                      style={styles.indicatorNoImage}
                                                      src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstar.png?alt=media&token=34b84207-ddbd-4f1b-9227-f33e4af2f31d"
                                                      alt="favourite-icon"
                                                    />
                                                  ) : (<></>)}
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          );
                                        }
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}{" "}
                              </>
                            )}
                          </>
                        </div>
                        {categ.id == "LmMOKf9tHFSrisSB6Woo" ? (
                          <div style={styles.imagesMenuContainer}>
                            <img
                              style={styles.imagesMenu1}
                              src="https://i.postimg.cc/9XL0DBZQ/cerdo.jpg"
                            // alt="favourite-icon"
                            />
                          </div>
                        ) : (<></>)}
                        {categ.id == "9v4CaIjgAjbkBmqJkDIw" ? (
                          <>
                            <div style={styles.imagesMenuContainer}>
                              <img
                                style={styles.imagesMenu2}
                                src="https://i.postimg.cc/FRM4S7rG/vaca.jpg"
                              // alt="favourite-icon"
                              />
                            </div>
                            <div style={styles.imagesMenuTextContainer}>
                              <h2 style={styles.imagesMenuText}>
                                UNA REINVENCIÓN DE LAS BRASAS, RESPETANDO SUS TIEMPOS
                                SOFISTICADO & CONTEMPORANEO MENÚ CON PRODUCTOS ARTESANALES,
                                CUIDADOSAMENTE ELABORADOS
                                & LOS FUEGOS QUE TRANSMITEN SU CALIDEZ
                              </h2>
                            </div>
                          </>
                        ) : (<></>)}
                        {categ.id == "uq2FBT8yqNcoLzHwOGMT" ? (
                          <div style={styles.imagesMenuContainer}>
                            <img
                              style={styles.imagesMenu3}
                              src="https://i.postimg.cc/CxGVqFXs/pulpo.png"
                            // alt="favourite-icon"
                            />
                          </div>
                        ) : (<></>)}
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
                {language === "spanish" ? (
                  <>
                    {aclarationSpanish !== "" ? (
                      <div style={styles.indications}>
                        <h6 style={styles.indicationsText}>
                          {aclarationSpanish}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {language === "english" ? (
                  <>
                    {aclarationEnglish !== "" ? (
                      <div style={styles.indications}>
                        <h6 style={styles.indicationsText}>
                          {aclarationEnglish}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {language === "portuguese" ? (
                  <>
                    {aclarationPortuguese !== "" ? (
                      <div style={styles.indications}>
                        <h6 style={styles.indicationsText}>
                          {aclarationPortuguese}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {language === "french" ? (
                  <>
                    {aclarationFrench !== "" ? (
                      <div style={styles.indications}>
                        <h6 style={styles.indicationsText}>
                          {aclarationFrench}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}{" "}
        </>
      ) : (
        <div style={styles.containerFlex}>
          <Loader
            type="TailSpin"
            color="#339AE7"
            height={100}
            width={100}
            timeout={10000} //3 secs
          />
        </div>
      )}
    </>
  );
};
