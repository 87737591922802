import React from "react";
import firebase from "../../../services/firebase";
import Sidebar from "../general/Sidebar";
import Hotel from "../../../restaurant/components/create/Hotel";
const DB = firebase.db;

class CreateHotelContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      appInService: false,
      backgroundImage:
        "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-palms-app.png?alt=media&token=6771073c-a51a-4b96-b785-347babd36c60",
      clientTotalNumber: 1000,
      direction: "",
      hotelInService: false,
      mail: "",
      name: "",
      phone: "",
      orderTotalNumber: 1000,
      totalMenues: 0,
      totalCategories: 0,
      totalProducts: 0,
      productsWithoutStock: 0,
      dashboardMenu: true,
      solutionMenu: true,
      titleApp: "Bienvenido! 😃",
      subtitleApp: "Gracias por elegirnos.",
      infoApp: "Probá nuestro nuevo Menú!",
      twoLanguages: false,
      qrMask: "",
      logoImage: "",
      primaryColor: "",
      whatsappNumber: "0",
      solutionWhatsapp: false,
      solutionInformationHotel:false,
      webURL:"",
      outlets:false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let doc = DB.collection("hoteles").doc();

    doc.set(this.state);

    firebase.succesfullMsg("Hotel successfully created!");
    this.props.history.push("/dashboard");
  }

  handleInput(e) {
    let key = e.target.name;
    let input = e.target.value;
    this.setState({
      [key]: input,
    });
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Hotel submit={this.handleSubmit} input={this.handleInput} />
      </div>
    );
  }
}

export default CreateHotelContainer;
