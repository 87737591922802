import React from "react";
import { Link } from "react-router-dom";

export default ({ handlerSubmit, handlerChange }) => {
  return (
    <div>
      <div
        className="row align-items-center"
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <div
          className="col"
          style={{
            width: "100%",
            //height: "1020px",
            height: "100vh",
            backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-login.png?alt=media&token=1d4690cf-c0fe-45c1-b7de-c2c62280d047"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2FLOVABLEHotels.png?alt=media&token=c6615d36-b397-4da8-8709-3238e02f5998"
            alt="..."
            //  height="22"
            style={{
              height: "5%",
              width: "auto",
              position: "relative",
              left: "10%",
              top: "80%",
            }}
          />
        </div>
        <div
          className="col"
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          {/* <img
            src="https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Flovablehotels-logo.png?alt=media&token=ebc8ebdc-3b44-4e74-8666-6e0acfded070"
            height="30"
            alt=""
            style={{
              marginLeft: "40px",
              marginBottom: "60px",
            }}
          /> */}
          <form
            onSubmit={(e) => handlerSubmit(e)}
            style={{
              padding: "40px",
              paddingRight: "40px",
              // marginRight: "20px",
            }}
          >
            <div className="form-group">
              <h1
                className="font-weight-bold"
                style={{
                  paddingBottom: "20px",
                }}
              >
                Inicio de Sesión
              </h1>
              <label>Corre electrónico</label>
              <input
                name="email"
                type="email"
                className="form-control"
                id="inputEmail"
                aria-describedby="emailHelp"
                onChange={handlerChange}
              />
              <small id="emailHelp" className="form-text text-muted">
                No compartiremos su información.
              </small>
            </div>
            <div
              className="form-group"
              style={{
                paddingBottom: "20px",
              }}
            >
              <label>Contraseña</label>
              <input
                name="password"
                type="password"
                className="form-control"
                id="inputPassword"
                onChange={handlerChange}
              />
            </div>
            <button
              onClick={(e) => handlerSubmit(e)}
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: "#FF2068",
                borderColor: "#FF2068",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Iniciar
            </button>

            <Link
              style={{
                marginLeft: "20px",
              }}
              to="/recover"
            >
              Recuperar contraseña
            </Link>
          </form>
        </div>
      </div>
      <div></div>
    </div>
  );
};
